import React, { Component } from 'react';
import { Button, Alert, Modal} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { withRouter, Redirect } from 'react-router-dom';
import { Localize } from '@visma/react-intl-helpers';
import FormULA from '@visma/form-ula';
import moment from 'moment';
import { Provider } from '../components/App/Authorize';
import SubmitWasSuccessful from '../components/SubmitWasSuccessful';
import connect from '../utils/react-refetch/connectAndRenderOnFulfilled';
import messages from '../components/intl/messages.properties';
import withFields from '../components/Pages/Fields/withFields';
import withFormGroups from '../components/Pages/FormGroups/withFormGroups';
import { CustomFieldsSchema, CustomFieldsUISchema } from "../components/CustomFields";
import logos from '../logos';

const NEW = 'NEW';
const DRAFT = 'DRAFT';
const SUBMITTED = 'SUBMITTED';

const View = connect(({ dataId, formId }) => {
  const final = {
    formFetch: `/api/form/${formId}`,
    save: data => ({
      saveResponse: {
        url: '/api/formdata',
        method: dataId ? 'PUT' : 'POST',
        data,
        force: true,
        andThen: () => do {
          ({
            dataFetch: {
              url: `/api/formdata/${dataId}`,
              force: true,
              refreshing: true
            }
          });
        }
      }
    }),
    dataFetch: `/api/formdata/${dataId}`
  };
  if (dataId === undefined) delete final.dataFetch;
  return final;
})(
  class View extends Component {
    state = {
      showModal: false,
      formData: {},
      showSuccess: true,
      shouldRefresh: false,
      dataFetchRevs: []
    };

    successTimeout;

    saveDraft = ({ formData }) => {
      window.scrollTo(0,0);
      this.save(formData, DRAFT);
      clearTimeout(this.successTimeout);
      this.successTimeout = null;

      if (this.props.professional) {
        this.setState({showSuccess: true, shouldRefresh: true});
      } else {
        this.setState({showSuccess: true});
      }
    };

    submit = ( formData ) => {
      this.save(formData, SUBMITTED);
      this.hideModal();
    };

    isNewRev = revToCheck => {
      const { dataFetchRevs } = this.state;

      return dataFetchRevs.filter(rev => rev === revToCheck).length <= 1;
    };

    save = (formData, status) => {
      const {
        props: { dataFetch: { _id, _rev } = {}, formFetch, save, userId, dataId, token },
        state: { dataFetchRevs },
        isNewRev
      } = this;

      this.setState({ formData, status, dataFetchRevs: [ ...dataFetchRevs, _rev ] }, () => {
        if (isNewRev(_rev)) {
          save({
            _id,
            _rev,
            form: {
              id: formFetch._id,
              rev: formFetch._rev
            },
            timestamp: Date.now(),
            values: formData,
            status,
            userId
          });
        } else {
          const fetchURL = `${process.env.APP_HOST}/api/formdata/${dataId}`;
          const fetchOptions = {
            headers: new Headers({
              Accept: 'application/json',
              Authorization: token,
              'Content-Type': 'application/json'
            })
          };

          fetch(fetchURL, fetchOptions)
            .then(response => response.json())
            .then(newDataFetch => {
              const {
                _id: fetchId,
                _rev: fetchRev,
                status: fetchStatus,
                error: fetchError,
                message: fetchMessage
              } = newDataFetch;

              const dataOk = (fetchStatus === DRAFT || fetchStatus === SUBMITTED);

              if (dataOk) {
                if (isNewRev(fetchRev)) {
                  this.setState({ dataFetchRevs: [ ...dataFetchRevs, fetchRev ] }, () => {
                    save({
                      _id: fetchId,
                      _rev: fetchRev,
                      form: {
                        id: formFetch._id,
                        rev: formFetch._rev
                      },
                      timestamp: Date.now(),
                      values: formData,
                      status,
                      userId
                    });
                  });
                }
              } else {
                console.log(`Data fetching error! Status: ${fetchStatus} ${fetchError}; Message: ${fetchMessage}`);
              }
            })
            .catch(error => {
              console.log('Data fetching error! ', error);
            });
        }
      });
    };

    getForm = formData => {
      const {
        props: { fields, formGroups, formFetch, FieldTemplate, ObjectFieldTemplate, ArrayFieldTemplate, professional = false, dataFetch, saveResponse }
      } = this;
      const logo = formFetch[7];

      return (
        <FormULA
          design={formFetch}
          disabled={dataFetch.status === SUBMITTED || saveResponse?.pending}
          isFetching={saveResponse?.pending}
          status={dataFetch.status}
          formData={formData}
          fields={fields}
          formGroups={formGroups}
          onSubmit={this.showModal}
          onSaveDraft={this.saveDraft}
          submitButtonMessageId={professional ? messages.archive : messages.submit}
          saveDraftButtonMessageId={professional ? messages.save : null}
          extraFieldTypes={{
            schema: {...CustomFieldsSchema},
            uiSchema: () => ({...CustomFieldsUISchema})
          }}
          FieldTemplate={FieldTemplate}
          ObjectFieldTemplate={ObjectFieldTemplate}
          ArrayFieldTemplate={ArrayFieldTemplate}
          logo={logo && logos[logo]}
        />
      );
    };

    showModal = ({formData}) => {
      this.setState({ showModal: true, formData: formData } );
    }

    hideModal = () => {
      this.setState({ showModal: false })
    }

    render() {
      const {
        props: {
          dataFetch,
          formFetch,
          history,
          locale,
          onSubmitRedirectTo,
          saveResponse,
          showPreview = true,
          professional = false
        },
        state: { formData, status, shouldRefresh }
      } = this;

      const logo = formFetch[7];

      const form = (
        <PrefillData
          id={formFetch._id}
          dataId={dataFetch._id}
          rev={formFetch._rev}
          isNew={dataFetch && (!dataFetch.status || dataFetch.status === NEW)}
          shouldRefresh={shouldRefresh}
          values={dataFetch && dataFetch.values}
        >
          {this.getForm}
        </PrefillData>
      );

      const savedViews = {
        [SUBMITTED]: () => (
          showPreview ? (
            <SubmitWasSuccessful
              design={formFetch}
              values={formData}
              logo={logo && logos[logo]}
              OtherButtons={() => (
                <Button
                  bsStyle="primary"
                  onClick={() => history.replace(onSubmitRedirectTo)}
                >
                  <FormattedMessage id={messages.close}/>
                </Button>
              )}
            />
          ) : ( <Redirect to={onSubmitRedirectTo} /> )
        )
      };

      // Fixme: Should be bound to pagination (show this when saving a single page form)
      if (professional) {
        if (this.state.showSuccess) {
          if (!this.successTimeout) {
            this.successTimeout = setTimeout(() => {
              this.setState({showSuccess: false});
            }, 10000)
          }
        }

        const alert = this.state.showSuccess && <><Alert variant="success">
          Tallennus onnistui!
        </Alert>
        <br/></>;

        savedViews[DRAFT] = () => (
          <>
            {alert}
            {form}
          </>
        )
      }

      return (
        <div className="form-ula">
          <Localize locale={locale} onChangeLocale={moment.locale}>
            <>
              <Modal show={this.state.showModal} onHide={this.hideModal}>
                <Modal.Header closeButton>
                  <Modal.Title><FormattedMessage id={professional ? messages.archiveModalTitle : messages.submitModalTitle} /></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <p><FormattedMessage id={professional ? messages.archiveModalBody : messages.submitModalBody} /></p>
                </Modal.Body>

                <Modal.Footer>
                  <Button bsStyle="default" onClick={this.hideModal}>
                    <FormattedMessage id={messages.cancel} />
                  </Button>

                  <Button bsStyle="primary" onClick={() => this.submit(this.state.formData)}>
                    <FormattedMessage id={professional ? messages.archive : messages.submit} />
                  </Button>
                </Modal.Footer>
              </Modal>

              {saveResponse?.fulfilled
                ? savedViews[status]?.() || form
                : form}
            </>
          </Localize>
        </div>
      );
    }
  }
    |> withFields
    |> withFormGroups
    |> withRouter
);

const PrefillData = connect(({ id, rev, dataId, isNew, shouldRefresh }) =>
  isNew ? {data: `/api/formdata/latest/${id}/${rev}`} :
  shouldRefresh ? {data: `/api/formdata/${dataId}/patient`} : {}
)(({ isNew, shouldRefresh, values, data, children }) =>
  children(isNew || shouldRefresh ? data.values : values)
);

export default ({ authToken, ...otherProps }) =>
  authToken ? (
    <Provider value={authToken}>
      <View {...otherProps} />
    </Provider>
  ) : (
    <View {...otherProps} />
  );
