
import { Button, Col, Modal, Row } from 'react-bootstrap';
import React, { useState, useRef, useEffect } from 'react';

import './style.scss';
import cloneDeep from 'lodash/cloneDeep';
import connect from "../../../../utils/react-refetch/connectAndRenderOnFulfilled";

const PatientInfo = (
  connect(() => ({
    patient: '/api/patient'
  }))(
    (({ patient: { name, nationalId} }) => (
      <>
        <span className="name">{name}</span>
        <span className="nationalId" style={{marginLeft: "10px  "}}>{nationalId}</span>
      </>

    ))
  )
)

const Box = ({title, rows, not, children=(<textarea rows={rows}></textarea>), ...props}) => {
  return (
    <Col {...props} className={`Box ${title} ${props.className || ''}`}>
      {not ? '' : <p>{title}</p>}
      {children}
    </Col>
  )
};

const FormField = ({index, formData, properties, OriginalData, ...className}) => {
  let unchanged;
  if (Array.isArray(formData[index]) && Array.isArray(OriginalData[index])) {
    unchanged = JSON.stringify(formData[index]) === JSON.stringify(OriginalData[index]);
  } else {
    unchanged = formData[index] === OriginalData[index];
  }
  return (
    <div className={`FormField ${unchanged ? 'autofilled' : 'notfilled'} ${className.className}`}>
      {properties[index].content}
    </div>
  );
}

function ObjectFieldTemplate(props) {
  const {properties, title, formData} = props;
  const OriginalData = useRef(formData).current;
  const [show, setShow] = useState(false);

  // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
  const handleBeforeUnload = e => {
    e.preventDefault();
    return e.returnValue = 'Oletko varma, että haluat sulkea ikkunan? Tallentamattomat tiedot menetetään.';
  }

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  function importToList() {
    const importData = document.getElementById("medicationDataTextarea").value.split("\n");

    let newArray = cloneDeep(properties[52].content.props.formData) || [];

    newArray = newArray !== undefined ? newArray : [];

    importData.forEach((line) => {
      if (line && line.trim().length) {
        newArray.push({0: false, 1: line.trim()})
      }
    });

    properties[52].content.props.onChange(newArray);

    setShow(false);
    document.getElementById("medicationDataTextarea").value = "";
  }

  const renders = {
    'Anestesia Esilomake' : () => (
      <div className="Page">
        <Row>
          <Col xs={5} xsOffset={7}>
            <Row className="Test" style={{marginBottom: '0px', borderBottomStyle: 'none'}}>
              <Box title='Tulo' rows={2} xs={6}>
                <Row>
                  <Col xs={4}>
                    <label>{properties[37].content}<span>LEIKO</span></label>
                    <label>{properties[38].content}<span>PÄIKI</span></label>
                  </Col>
                  <Col xs={8}>
                    <label>{properties[39].content}<span>Osasto</span></label>
                    <label>{properties[40].content}<span>Päivystys</span></label>
                  </Col>
                </Row>
              </Box>
              <Box title='Jatko' rows={2} xs={6}>
                <Row>
                  <Col xs={5}>
                    <label>{properties[41].content}<span>Yön yli</span></label>
                    <label>{properties[42].content}<span>Osasto</span></label>
                  </Col>
                  <Col xs={7}>
                    <label>{properties[43].content}<span>Kotiutuja</span></label>
                    <label>{properties[44].content}<span>Tarkkailu</span></label>
                  </Col>
                </Row>
              </Box>
            </Row>
          </Col>
        </Row>
        <Row className="Test" style={{marginTop: '0px'}}>
          <Row className="outerRow">
            <Col xs={6}>
              <Row>
                <Box title='Nimi-henkilötunnus' rows={6} xs={12} className="NoTitle tall" style={{height: "146px"}}>
                  {/*properties[0].content*/}

                  <p className="patientInfo" >
                    <PatientInfo/>
                  </p>
                </Box>
              </Row>
              <Row>
                <Box title='Preop. diagnoosi' rows={3} xs={12} className="NoTitle">
                  {/*properties[1].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={1}/>
                </Box>
              </Row>
            </Col>
            <Col xs={6}>
              <Row>
                <Box title='Haastatteluaika/haastattelija' rows={1} xs={6} className="NoTitle">
                  {/*properties[45].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={45}/>
                </Box>
                <Box title='Toimenpidepäivä' rows={1} xs={6} className="NoTitle">
                  {/*properties[46].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={46}/>
                </Box>
              </Row>
              <Row>
                <Box title='Erikoisala' rows={1} xs={3} className="NoTitle">
                  {/*properties[47].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={47}/>
                </Box>
                <Box title='Kiireellisyys' rows={1} xs={3} className="NoTitle">
                  {/*properties[48].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={48}/>
                </Box>
                <Box title='Leikkauksen arvioitu kesto' rows={1} xs={6} className="NoTitle">
                  {/*properties[49].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={49}/>
                </Box>
              </Row>
              <Row>
                <Box title='Antimikrobiprofylaksia' rows={1} xs={12} className="NoTitle">
                  {/*properties[50].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={50}/>
                </Box>
              </Row>
              <Row>
                <Box title='Suunniteltu tmp' rows={3} xs={12} className="NoTitle">
                  {/*properties[51].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={51}/>
                </Box>
              </Row>
            </Col>
          </Row>

          <Row className="outerRow">
            <Col xs={6}>
              <Row>
                <Box title='Ikä' rows={1} xs={3} className="NoTitle">
                  {/*properties[2].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={2}/>
                </Box>
                <Box title='Pituus' rows={1} xs={3} className="NoTitle">
                  {/*properties[3].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={3}/>
                </Box>
                <Box title='Paino' rows={1} xs={3} className="NoTitle">
                  {/*properties[4].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={4}/>
                </Box>
                <Box title='BMI' rows={1} xs={3} className="NoTitle">
                  {/*properties[5].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={5}/>
                </Box>
              </Row>
              <Row>
                <Box title='Sairaudet' rows={13} xs={12} not>
                  <Col xs={12}>
                    <Row>
                      <Col xs={8} style={{paddingLeft: '0px'}}>
                        <div className="title">Sairaudet</div>
                      </Col>
                      <Col xs={4}>
                        <label>{properties[6].content}<span>Ei ole</span></label>
                      </Col>
                    </Row>
                  </Col>
                  {/*properties[7].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={7}/>
                </Box>
              </Row>
              <Row>
                <Box title='Aiemmat anestesiat / leikkaukset (ongelmat)' rows={9} xs={12} not>
                  <Col xs={12}>
                    <Row>
                      <Col xs={8} style={{paddingLeft: '0px'}}>
                        <div className="title">Aiemmat anestesiat / leikkaukset (ongelmat)</div>
                      </Col>
                      <Col xs={4}>
                        <label>{properties[8].content}<span>Ei ole</span></label>
                      </Col>
                    </Row>
                  </Col>
                  {/*properties[9].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={9}/>
                </Box>
              </Row>
              <Row>
                <Box title='Allergiat (oire)' rows={6} xs={12} not className="tall">
                  <Col xs={12}>
                    <Row>
                      <Col xs={8} style={{paddingLeft: '0px'}}>
                        <div className="title">Allergiat (oire)</div>
                      </Col>
                      <Col xs={4}>
                        <label>{properties[10].content}<span>Ei ole</span></label>
                      </Col>
                    </Row>
                  </Col>
                  {/*properties[11].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={11}/>
                </Box>
              </Row>
              <Row>
                <Box title='Alko' rows={1} xs={6} className="NoTitle">
                  {/*properties[12].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={12}/>
                </Box>
                <Box title='Tupakka' rows={1} xs={6} className="NoTitle">
                  {/*properties[13].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={13}/>
                </Box>
              </Row>
            </Col>
            <Col xs={6}>
              <Row>
                <Box title='Nykylääkitys' rows={36} xs={12}>
                  <Col xs={2}>
                    <label>{properties[101].content}<span>Ei ole</span></label>
                  </Col>
                  {/*properties[52].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={52}/>
                  <Button onClick={() => setShow(true)} bsStyle="primary" style={{height: "31px", position: "relative", left:90, top:-31}}>Tuo lääkkeet</Button>
                </Box>
              </Row>
            </Col>
          </Row>

          <Row className="outerRow">
            <Col xs={6}>
              <Row>
                <Box title='Pvm' rows={1} xs={2} className="NoTitle">
                  {/*properties[14].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={14}/>
                </Box>
                <Box title='Hb' rows={1} xs={1} className="NoTitle">
                  {/*properties[15].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={15}/>
                </Box>
                <Box title='Tromb' rows={1} xs={2} className="NoTitle">
                  {/*properties[16].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={16}/>
                </Box>
                <Box title='TT/INR' rows={1} xs={2} className="NoTitle">
                  {/*properties[17].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={17}/>
                </Box>
                <Box title='K' rows={1} xs={1} className="NoTitle">
                  {/*properties[18].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={18}/>
                </Box>
                <Box title='Na' rows={1} xs={1} className="NoTitle">
                  {/*properties[19].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={19}/>
                </Box>
                <Box title='Krea/gfr' rows={1} xs={3} className="NoTitle">
                  {/*properties[20].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={20}/>
                </Box>
              </Row>
              <Row>
                <Box title='Muut' rows={1} xs={12} className="NoTitle">
                  {/*properties[21].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={21}/>
                </Box>
              </Row>
            </Col>

            <Col xs={6}>
              <Row>
                <Box title='THX Pvm' rows={4} xs={9}>
                  <Col xs={12}>
                    {/*properties[97].content*/}
                    <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={97}/>
                    {/*properties[98].content*/}
                    <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={98}/>
                  </Col>
                  <Col xs={12}>
                    {/*properties[53].content*/}
                    <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={53}/>
                  </Col>
                </Box>
                <Col xs={3}>
                  <Row>
                    <Box title='RR' rows={1} xs={6} className="NoTitle">
                      {/*properties[54].content*/}
                      <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={54}/>
                    </Box>
                    <Box title='Syke' rows={1} xs={6} className="NoTitle">
                      {/*properties[55].content*/}
                      <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={55}/>
                    </Box>
                  </Row>
                  <Row>
                    <Box title='SpO2' rows={1} xs={6} className="NoTitle">
                      {/*properties[56].content*/}
                      <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={56}/>
                    </Box>
                    <Box title='HF(/min)' rows={1} xs={6} className="NoTitle">
                      {/*properties[57].content*/}
                      <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={57}/>
                    </Box>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>


          <Row className="outerRow">
            <Col xs={6}>
              <Row>
                <Box title='Veriryhmä' rows={1} xs={2} className="NoTitle">
                  {/*properties[22].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={22}/>
                </Box>
                <Box title='X- veri' rows={1} xs={2} className="NoTitle">
                  {/*properties[23].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={23}/>
                </Box>
                <Box title='Verivaraus' rows={1} xs={4} className="NoTitle">
                  {/*properties[24].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={24}/>
                </Box>
                <Box title='Vasta-aineet' rows={1} xs={4} className="NoTitle">
                  {/*properties[25].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={25}/>
                </Box>
              </Row>
            </Col>
              <Col xs={6}>
                <Row>
                  <Box title='EKG' rows={1} xs={12} className="NoTitle">
                    {/*properties[58].content*/}
                    <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={58}/>
                  </Box>
                </Row>
              </Col>
          </Row>

          <Row className="outerRow">
            <Col xs={6}>
              <Row>
                <Box title='Vuoto / tukostaipumus' rows={4} xs={12} className="Vuoto">
                  <Col xs={4}>
                    {/*properties[26].content*/}
                    <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={26}/>
                    {/*properties[27].content*/}
                    <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={27}/>
                    {/*properties[28].content*/}
                    <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={28}/>
                </Col>
                <Col xs={8}>
                  {/*properties[100].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={100}/>
                </Col>
                </Box>
              </Row>
              <Row>
                <Box title='Ane-lääkärin konsult./ nimik.' rows={9} xs={12} not>
                  <Row>
                    <Col xs={5}>
                      <label>Ane-lääkärin konsult./ nimik.</label>
                    </Col>
                    <Col xs={1}>
                      {/*properties[29].content*/}
                      <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={112} className={"NoTitle"}/>
                    </Col>
                    <Col xs={2}>
                      {/*properties[29].content*/}
                      <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={29}/>
                    </Col>
                    <Col xs={2}>
                      {/*properties[30].content*/}
                      <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={30}/>
                    </Col>
                    <Col xs={2}>
                      {/*properties[31].content*/}
                      <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={31}/>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={1}>
                      {/*properties[32].content*/}
                      <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={32}/>
                    </Col>
                    <Col xs={11}>
                      {/*properties[33].content*/}
                      <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={33}/>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={9}>
                      {/*properties[34].content*/}
                      <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={34}/>
                    </Col>
                    <Col xs={3}>
                      {/*properties[35].content*/}
                      <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={35}/>
                    </Col>
                    <Col xs={3}>
                      {/*properties[36].content*/}
                      <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={36}/>
                    </Col>
                  </Row>
                </Box>
              </Row>
            </Col>
            <Col xs={6}>
              <Row>
                <Box title='Preop. status + tutkimukset' rows={9} xs={12} not>
                  <Row>
                    <Col xs={5}>
                      <label>Preop. status + tutkimukset</label>
                    </Col>
                    <Col xs={4}>
                      {/*properties[99].content*/}
                      <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={99}/>
                    </Col>
                    <Col xs={3}>
                      {/*properties[59].content*/}
                      <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={59}/>
                    </Col>
                  </Row>
                  {/*properties[60].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={60}/>
                </Box>
              </Row>
              <Row>
                <Box title='Aspiraatioriski' rows={1} xs={3} className="NoTitle">
                  {/*properties[61].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={61}/>
                </Box>
                <Box title='Vaikea ilmatie' rows={1} xs={3} className="NoTitle">
                  {/*properties[62].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={62}/>
                </Box>
                <Box title='Juonut/syönyt' rows={1} xs={3} className="NoTitle">
                  {/*properties[63].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={63}/>
                </Box>
                <Box title='Virtsannut' rows={1} xs={3} className="NoTitle">
                  {/*properties[64].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={64}/>
                </Box>
              </Row>
              <Row>
                <Box title='Proteesit / implantit' rows={1} xs={12} className="NoTitle">
                  {/*properties[65].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={65}/>
                </Box>
              </Row>
            </Col>
          </Row>
        </Row>

        <div className="page-break"/>

        <Row className="Test">
          <Row className="outerRow">
            <Box title='Nimi - henkilötunnus' rows={4} xs={12} className="NoTitle tall">
              {/*properties[66].content*/}
              <p style={{minHeight: "40px"}}
                 className="patientInfo" >
                <PatientInfo/>
              </p>
            </Box>
          </Row>
        </Row>

        <Row className="Test">
          <Row className="outerRow">
            <Col xs={12}>
              <Row>
                <Box title='Ruokavalio' rows={4} xs={12} className="NoTitle">
                  {/*properties[67].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={67}/>
                </Box>
              </Row>
              <Row>
                <Box title='Pot. halukas peruutuspaikalle' rows={1} xs={12} not>
                  {properties[68].content}
                </Box>
              </Row>
              <Row>
                <Box title='AE-sukat' rows={1} xs={12} not>
                  {properties[69].content}
                </Box>
              </Row>
              <Row>
                <Box title='Saa ottaa saliin ilman, että operatööri nähnyt' rows={6} xs={12} not>
                  {properties[70].content}
                  {properties[71].content}
                  {/*properties[102].content*/}
                  <Row>
                    <Col xs={1}>
                      Hakija, puh
                    </Col>
                    <Col xs={11}>
                      <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={102}/>
                    </Col>
                  </Row>
                  {properties[72].content}
                  {/*properties[103].content*/}
                  <Row>
                    <Col xs={2}>
                      Yön yli seura, puh
                    </Col>
                    <Col xs={10}>
                      <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={103}/>
                    </Col>
                  </Row>
                </Box>
              </Row>
            </Col>
          </Row>
        </Row>

        <Row className="Test">
          <Row className="outerRow">
            <Box title='Muuta huomioitavaa' rows={30} xs={12} className="NoTitle">
              {/*properties[73].content*/}
              <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={73}/>
            </Box>
          </Row>
        </Row>

        <Row className="Test">
          <Row className="outerRow">
            <Box title='Pot. preop. ohjaus' rows={6} xs={6} not className="Ohjaus">
              <Row>
                <Col xs={6}>
                  <label>Pot. preop.ohjaus</label>
                </Col>
                <Col xs={6}>
                  <Row>
                    <Col xs={6}>
                      <label>{properties[74].content}<span>Suullinen</span></label>
                    </Col>
                    <Col xs={6}>
                      <label>{properties[75].content}<span>Kirjallinen</span></label>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="Values">
                <Col xs={6}>
                  <label>{properties[76].content}<span>Oma lääkitys</span></label>
                  {/*(<div className="NoTitle">{properties[104].content}</div>)*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={104} className={"NoTitle"}/>
                  <label>{properties[77].content}<span>Ihon kunto</span></label>
                  {/*(<div className="NoTitle">{properties[105].content}</div>)*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={105} className={"NoTitle"}/>
                  <label>{properties[78].content}<span>Puhtaus</span></label>
                  {/*(<div className="NoTitle">{properties[106].content}</div>)*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={106} className={"NoTitle"}/>
                  <label>{properties[79].content}<span>Vaatetus</span></label>
                  {/*(<div className="NoTitle">{properties[107].content}</div>)*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={107} className={"NoTitle"}/>
                </Col>
                <Col xs={6}>
                  <label>{properties[80].content}<span>Ravinnotta olo</span></label>
                  {/*(<div className="NoTitle">{properties[108].content}</div>)*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={108} className={"NoTitle"}/>
                  <label>{properties[81].content}<span>Todistusten tarve</span></label>
                  {/*(<div className="NoTitle">{properties[109].content}</div>)*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={109} className={"NoTitle"}/>
                  <label>{properties[82].content}<span>Oman auton käyttö</span></label>
                  {/*(<div className="NoTitle">{properties[110].content}</div>)*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={110} className={"NoTitle"}/>
                  <label>{properties[83].content}<span>Toimenpide kelp.</span></label>
                  {/*(<div className="NoTitle">{properties[111].content}</div>)*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={111} className={"NoTitle"}/>
                </Col>
              </Row>
            </Box>
            <Box title='Onko vaiva' rows={6} xs={6} not className="Vaivat">
              <label>Onko vaiva</label>
              <Row>
                <Col xs={3}>
                  <label>{properties[84].content}<span>pahentunut</span></label>
                </Col>
                <Col xs={3}>
                  <label>{properties[85].content}<span>ennallaan</span></label>
                </Col>
                <Col xs={3}>
                  <label>{properties[86].content}<span>vähentynyt</span></label>
                </Col>
              </Row>
              <label>Onko viimeisen kuukauden aikana</label>
              <Row>
                <Col xs={3}>
                  <label>{properties[87].content}<span>nuhaa</span></label>
                </Col>
                <Col xs={3}>
                  <label>{properties[88].content}<span>yskää</span></label>
                </Col>
                <Col xs={3}>
                  <label>{properties[89].content}<span>kurkkukipua</span></label>
                </Col>
                <Col xs={3}>
                  <label>{properties[90].content}<span>lämpöilyä</span></label>
                </Col>
              </Row>
              <label>Leikkausalueen ihon kunto</label>
              <Row>
                <Col xs={3}>
                  <label>{properties[91].content}<span>kunnossa</span></label>
                </Col>
                <Col xs={2}>
                  <label>{properties[92].content}<span>ongelma:</span></label>
                </Col>
                <Col xs={7}>
                  {/*properties[93].content*/}
                  <FormField formData={formData} OriginalData={OriginalData} properties={properties} index={93}/>
                </Col>
              </Row>
              <label>Anestesiatoive</label>
              <Row>
                <Col xs={3}>
                  <label>{properties[94].content}<span>puudutus</span></label>
                </Col>
                <Col xs={3}>
                  <label>{properties[95].content}<span>nukutus</span></label>
                </Col>
                <Col xs={3}>
                  <label>{properties[96].content}<span>ei väliä</span></label>
                </Col>
              </Row>
            </Box>
          </Row>
        </Row>
        <Modal show={show}>
          <Modal.Header closeButton onClick={() => setShow(false)} >
            Tuo potilaan lääkitys
          </Modal.Header>
          <Modal.Body style={{ maxHeight:"500px" }}>
            <textarea id="medicationDataTextarea" onInput={(event) => { event.target.style.height = ""; event.target.style.height = event.target.scrollHeight + "px" }} style={{ width: "100%", maxHeight: "500px" }}/>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShow(false)} bsStyle="link">Takaisin</Button>
            <Button onClick={importToList} bsStyle="primary">Tuo</Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
  return renders[title] ? renders[title]() : properties.map(({ content }) => content);
}

export default ObjectFieldTemplate;
