import { mapKeys } from 'lodash';
import FIELD_ID from '@visma/form-ula/es/constants/fieldIDs';

const keys = object =>
  Object.keys(object).reduce(
    (keys, key, index) => ({ ...keys, [index]: key }),
    {}
  );

export default elements => {
  const elementKeys = keys(elements);
  return formData =>
    mapKeys(
      formData,
      (_, id) => do {
        const key = elementKeys[id];
        key === undefined ? id : FIELD_ID[key];
      }
    );
};
