import { injectIntl } from 'react-intl';
import { FormGroup, ListGroup } from 'react-bootstrap';
import { FIELD } from '../../../../forms';
import connect from '../../../../utils/react-refetch/connectAndRenderOnFulfilled';
import ButtonNew from '../../../ButtonNew';
import Filter from '../../../Filter';
import ListGroupItem from '../../../lists/ListGroupItem';
import Header from '../../../lists/ListGroupItem/Header';
import { getLanguage } from '../../../utils/withLocalize';
import FieldCount from '../../../lists/FieldCount';
import messages from '../../../intl/messages.properties';

export default connect(() => ({
  formsFetch: '/api/form'
}))(
  (({ formsFetch, intl: { locale } }) => (
    <>
      <FormGroup>
        <ButtonNew id={messages.newForm} />
      </FormGroup>
      <Filter items={formsFetch} path={[FIELD.TITLE, getLanguage(locale)]}>
        {({ items, searchWords }) => (
          <ListGroup>
            {items.sort((a,b) => a[FIELD.TITLE][locale.slice(0,2).toLowerCase()] > b[FIELD.TITLE][locale.slice(0,2).toLowerCase()]).map(item => (
              <ListGroupItem
                key={item._id}
                item={item}
                header={
                  <>
                    <Header
                      name={item[FIELD.TITLE]}
                      searchWords={searchWords}
                    />{` ( ${['Potilaskysely', 'Ammattilaiskysely'][item[FIELD.FORM_TYPE]]} ) `}
                    <FieldCount elements={item[FIELD.ELEMENTS]} />
                  </>
                }
              >
                {item[FIELD.NOTES] ||
                  // Zero width space.
                  '​'}
              </ListGroupItem>
            ))}
          </ListGroup>
        )}
      </Filter>
    </>
  )) |> injectIntl
);
