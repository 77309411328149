import React from 'react';
import { Row, Col, ButtonToolbar } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import { FIELD as FORM_ELEMENTS } from '../forms';
import ButtonPrint from './ButtonPrint';
import withLocalize from './utils/withLocalize';
import messages from './intl/messages.properties';
import Fields from './Pages/Values/View/Form/Fields';

export default (({ OtherButtons, design, localize, values, logo }) => {
  const header = logo ? (
    <Row>
      <Col xs={12} sm={9}>
        <ReactMarkdown source={localize(design[FORM_ELEMENTS.SUCCESS_TEXT])}/>
      </Col>

      <Col xs={12} sm={3}>
        <img src={logo.src} alt={logo.alt}/>
      </Col>
    </Row>
  ) : (
    <Row>
      <Col xs={12}>
        <ReactMarkdown source={localize(design[FORM_ELEMENTS.SUCCESS_TEXT])}/>
      </Col>
    </Row>
  )

  return (
    <>
      {header}

      <Row>
        <Col xs={12}>
          <h2>
            <FormattedMessage id={messages.values}/>
          </h2>

          <Fields elements={design[FORM_ELEMENTS.ELEMENTS]} values={values}/>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <ButtonToolbar>
            <OtherButtons/>
            <ButtonPrint/>
          </ButtonToolbar>
        </Col>
      </Row>
    </>
  );
}) |> withLocalize;
