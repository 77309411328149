export default Component => {
  Component.instances = new Set();
  return class TrackInstances extends Component {
    componentDidMount() {
      Component.instances.add(this);
      super.componentDidMount?.call(this);
    }

    componentWillUnmount() {
      Component.instances.delete(this);
      super.componentWillUnmount?.call(this);
    }
  };
};
