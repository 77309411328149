import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Nav, Navbar, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import classNames from 'classnames';
import messages from '../../intl/messages.properties';
import Language from './Language';
import { Role, CONFIGURER, PROFESSIONAL } from '../../Role';

export default () => (
  <Navbar staticTop>
    <Role is={CONFIGURER}>
      <Navbar.Header>
        <Navbar.Brand>
          <Link to="/" style={{ padding: '15px' }}>
            ️Oma Raitti Form-ULA{' '}
            <span
              role="img"
              aria-label=""
              style={{ fontSize: 'xx-large', lineHeight: 0 }}
            >
              🏎️
            </span>
            {/* <img
              src={require('../../../images/omaraitti-lomakepalvelu.png')}
              alt="logo"
              style={{height: "40px"}}
            /> */}
          </Link>
        </Navbar.Brand>
      </Navbar.Header>
    </Role>

    <Nav>
      <Role is={PROFESSIONAL}>
        <LinkContainer to="/">
          <NavItem eventKey={1} className={classNames('navitem', 'navitem--blue')}>
            <FormattedMessage id={messages.frontpage} />
          </NavItem>
        </LinkContainer>
      </Role>

      <Role is={CONFIGURER}>
        <LinkContainer to="/forms">
          <NavItem eventKey={2}>
            <FormattedMessage id={messages.forms} />
          </NavItem>
        </LinkContainer>

        <LinkContainer to="/formgroups">
          <NavItem eventKey={3}>
            <FormattedMessage id={messages.formgroups} />
          </NavItem>
        </LinkContainer>

        <LinkContainer to="/fields">
          <NavItem eventKey={4}>
            <FormattedMessage id={messages.fields} />
          </NavItem>
        </LinkContainer>

        <LinkContainer to="/values">
          <NavItem eventKey={5}>
            <FormattedMessage id={messages.values} />
          </NavItem>
        </LinkContainer>
      </Role>

      <Role is={PROFESSIONAL}>
        <LinkContainer to="/timeline">
          <NavItem eventKey={6} className={classNames('navitem', 'navitem--green')}>
            <FormattedMessage id={messages.videoAppointments} />
          </NavItem>
        </LinkContainer>
      </Role>
    </Nav>

    <Nav pullRight>
      <Language />
    </Nav>
  </Navbar>
);
