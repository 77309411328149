import React, { Component } from 'react';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import moment from 'moment';
import 'moment/locale/fi'
import { injectIntl } from 'react-intl';
import 'react-dates/lib/css/_datepicker.css';
import messages from './../../intl/messages.properties';

class LimitedDateEdit extends Component {
  state = {};

  handleFocusChange = ({ focused }) => this.setState({ focused });

  render() {
    const {
      props: {
        id,
        intl: { formatMessage },
        onChange,
        value
      },
      state: { focused }
    } = this;

    // Start of date range: today minus 6 months
    const startDate = moment().subtract(6, 'months');

    // End of date range: today
    const endDate = moment();

    return (
      <SingleDatePicker
        date={value ? moment(value) : null}
        displayFormat="D.M.YYYY"
        onDateChange={date => onChange(date ? date.format('YYYY-MM-DD') : null)}
        focused={focused}
        readOnly
        onFocusChange={this.handleFocusChange}
        id={id}
        placeholder={formatMessage({ id: messages.date })}
        hideKeyboardShortcutsPanel
        isOutsideRange={date =>
          (startDate && moment(date).isBefore(startDate)) ||
          (endDate && moment(date).isAfter(endDate))
        }
      />
    );
  }
}

export default LimitedDateEdit |> injectIntl;
