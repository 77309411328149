
import { Button, Row, Col } from 'react-bootstrap';

const keys = ['a','b','c','d','e'];

const getLabel = (item, value) => {
  const json = value ? JSON.parse(value) : [];
  const found = json.filter( v => v[0] === item);
  return found[0] && keys[json.indexOf(found[0])];
}

const RenderButton = ({children, ...rest}) => {
  return (
    <Col xs={4} {...rest}>
      {
        children && (
          <Button variant="secondary" size="sm">
            {
              children
            }
          </Button>
        )
      }
    </Col>
  );
}

export default ({value}) => (
  <div className="thx">
    <div className="buttons">
      <Row>
        {
          keys.filter((v, i) => i < 2).map((item, i) => <RenderButton key={item} xsOffset={i === 1 ? 4 : undefined} >{getLabel(item, value)}</RenderButton>)
        }
      </Row>
      <Row>
        {
          keys.filter((v, i) => i === 2).map(item => <RenderButton key={item} xsOffset={4} >{getLabel(item, value)}</RenderButton>)
        }
      </Row>
      <Row>
        {
          keys.filter((v, i) => i > 2).map((item, i) => <RenderButton key={item} xsOffset={i === 1 ? 4 : undefined} >{getLabel(item, value)}</RenderButton>)
        }
      </Row>
    </div>
    <div>
      {
        (value ? JSON.parse(value) : []).map((item, i) => (
          <Row  key={i}>
            <span>{keys[i]} : {item[1]}</span>
          </Row>
        ))
      }
    </div>
  </div>
);
