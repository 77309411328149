export const upcoming = '../../src/components/Pages/Timeline/intl:upcoming';
export const past = '../../src/components/Pages/Timeline/intl:past';
export const appointmentTime = '../../src/components/Pages/Timeline/intl:appointmentTime';
export const status = '../../src/components/Pages/Timeline/intl:status';
export const joinUrl = '../../src/components/Pages/Timeline/intl:joinUrl';
export const noevents = '../../src/components/Pages/Timeline/intl:noevents';
export const approved = '../../src/components/Pages/Timeline/intl:approved';
export const notApproved = '../../src/components/Pages/Timeline/intl:notApproved';
export const linktext = '../../src/components/Pages/Timeline/intl:linktext';
export const linkerror = '../../src/components/Pages/Timeline/intl:linkerror';
export const department = '../../src/components/Pages/Timeline/intl:department';
export default {upcoming, past, appointmentTime, status, joinUrl, noevents, approved, notApproved, linktext, linkerror, department};
