import Create from '../../Editor/Create';
import Editor from './Editor';

export default props => (
  <Create
    Editor={Editor}
    apiPath="formgroup"
    routePath="formgroups"
    {...props}
  />
);
