import { mapKeys } from 'lodash';
import indices from '@visma/form-ula/es/constants/indices';
import { fields } from '@visma/form-ula/es/constants/fieldIDs';
import { BOOLEAN, RICHTEXT, STRING_PAIR, TEXT } from '@visma/form-ula/es/constants/fieldTypes';
import * as FIELD_TYPE from "@visma/form-ula/es/constants/fieldTypes";
import { CustomFieldsTypes } from "../CustomFields";

const editorFields = {
  ...fields,
  SUCCESS_TEXT: {
    NAME: { fi: 'Yhteenveto' },
    LOCALIZE: true,
    HELP: {
      fi:
        'Teksti näkyy lomakkeen lähetyksen jälkeen. Sivulla näkyy myös vastaukset ja tulostuspainike.'
    },
    TYPE: RICHTEXT
  },
  TYPE: {
    ...fields.TYPE,
    CHOICES: [
      ...fields.TYPE.CHOICES,
      ...CustomFieldsTypes
    ]
  },
  POINTS: {
    NAME: { fi: 'Pisteytys' },
    TYPE: BOOLEAN
  },
  CHOICES_POINTS: {
    NAME: { fi: 'Vaihtoehtojen pisteytys' },
    TYPE: TEXT,
    LIST: true,
  },
  SHOW_POINTS: {
    NAME: { fi: 'Näytä pisteet täyttäjälle' },
    TYPE: BOOLEAN
  },
  POINT_TRESHOLDS: {
    NAME: { fi: 'Pisterajat' },
    TYPE: STRING_PAIR,
    LABELS: "Minimipistemäärä,Selite",
    LIST: true,
  },
  LABELS: {
    NAME: { fi: 'Otsikot' },
    TYPE: STRING_PAIR,
    LABELS: ""
  },
  SHOW_LETTERS: {
    NAME: { fi: 'Näytä kirjaimet' },
    TYPE: BOOLEAN
  },
  FORM_TYPE: {
    NAME: {
      fi: 'Lomake Tyyppi'
    },
    LOCALIZE: true,
    HELP: {
      fi: 'Onko kysely tarkoitettu potilaalle vai ammattilaisille.'
    },
    TYPE: FIELD_TYPE.SELECT,
    CHOICES: [
      {
        fi: 'Potilas'
      },
      {
        fi: 'Ammattilainen'
      }
    ],
    PLACEHOLDER: { fi: 'Valitse tyyppi' }
  },
  LOGO: {
    NAME: { fi: 'Logo' },
    TYPE: FIELD_TYPE.SELECT,
    PLACEHOLDER: { fi: 'Valitse logo' },
    CHOICES: [
      { fi: 'Ei logoa' },
      { fi: 'Sydänsairaala' }
    ]
  },
};

export const FIELD = indices(editorFields);

export default Object.values(editorFields).map((field, _id) => ({
  _id,
  ...mapKeys(field, (_, key) => FIELD[key])
}));
