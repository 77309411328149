import React from 'react';
import { ButtonToolbar, FormGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import connect from '../../../../utils/react-refetch/connectAndRenderOnFulfilled';
import ButtonPrint from '../../../ButtonPrint';
import LocalStorage from '../../../LocalStorage';
import messages from '../../../intl/messages.properties';
import NoPrint from '../../../NoPrint';
import FormName from '../FormName';
import Form from './Form';
import { Provider, defaultFormat } from './ChooseDisplayFormat';
import logos from '../../../../logos'

export default connect(({ match: { params: { id } } }) => ({
  dataFetch: `/api/formdata/${id}`,
  formFetch: `/api/form`
}))(({ dataFetch, dataFetch: { form, timestamp }, formFetch }) => (
  <LocalStorage itemKey={module.id}>
    {({ value = defaultFormat }) => {
      const formName = <FormName form={formFetch.find(f => f._id === form.id)}/>;
      const date = timestamp && new Date(timestamp).toLocaleString();
      const logo = formFetch.find(f => f._id === form.id)[7];
      const logoInfo = logos[logo];

      return (
        <div className='valuesView'>
          <Provider value={value}>
            <div className={classNames('printHide', 'header')}>
              <h1>
                <FormattedMessage id={messages.values}/>

                <span className="subTitle">{formName} {date}</span>
              </h1>

              {logo && (
                <img src={logoInfo.src} alt={logoInfo.alt}/>
              )}
            </div>

            <Form data={dataFetch}/>

            <div className="printHide">
              <FormGroup>
                <NoPrint>
                  <ButtonToolbar className="pull-right">
                    <ButtonPrint/>
                  </ButtonToolbar>
                </NoPrint>
              </FormGroup>
              <FormGroup>​{/* Zero width space. */}</FormGroup>
            </div>
          </Provider>
        </div>
      );
    }}
  </LocalStorage>
));
