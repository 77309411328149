import { Label } from 'react-bootstrap';
import { Fragment } from 'react';
import { FIELD } from '../../../../../../../fields';
import withLocalize from '../../../../../../utils/withLocalize';

export default (({ localize, value, field: { [FIELD.CHOICES]: choices } }) =>
  value?.map(value => (
    <Fragment key={value}>
      <Label bsStyle="primary">{localize(choices[value])}</Label>{' '}
    </Fragment>
  )) || null) |> withLocalize;
