import { FIELD } from '../../../../forms';

export default ({ elements, notes, successText, title, type, logo, ...rest }) => ({
  ...rest,
  [FIELD.ELEMENTS]: elements,
  [FIELD.NOTES]: notes,
  [FIELD.SUCCESS_TEXT]: successText,
  [FIELD.TITLE]: title,
  [FIELD.FORM_TYPE]: type,
  [FIELD.LOGO]: logo
});
