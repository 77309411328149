import { mapValues } from 'lodash';
import mode, { DEVELOPMENT, MOCK, PRODUCTION } from '../../mode';
import Mock from './Mock';
import convertData from './convertData';

const withHost = propsToOptions =>
  convertData(
    props =>
      propsToOptions(props)
      |> (options =>
        mapValues(options, option =>
          ({
            function: () => data =>
              mapValues(option(data), options => ({
                ...mapValues(options, option =>
                  typeof option === 'function'
                    ? request =>
                        mapValues(option(request), (option = {}) => ({
                          ...option,
                          url: `${process.env.APP_HOST}${option.url}`
                        }))
                    : option
                ),
                url: `${process.env.APP_HOST}${options.url}`
              })),
            object: () => ({
              ...option,
              url: `${process.env.APP_HOST}${option.url}`
            }),
            string: () => `${process.env.APP_HOST}${option}`
          }[typeof option]())
        ))
  );

export default {
  [DEVELOPMENT]: withHost,
  [MOCK]: propsToOptions => Component => props => (
    <Mock {...props} propsToOptions={propsToOptions} Component={Component} />
  ),
  [PRODUCTION]: withHost
}[mode];
