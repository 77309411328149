import { FIELD } from '../../../forms';

export default [
  {
    [FIELD.TITLE]: {
      fi: 'Esimerkkilomake',
      en: 'Example form'
    },
    [FIELD.NOTES]: 'Tässä tärkeitä muistiinpanoja koskien esimerkkilomaketta.',
    [FIELD.ELEMENTS]: [
      {
        type: 'field',
        id: 0,
        required: true
      },
      {
        type: 'field',
        id: 1
      },
      {
        type: 'field',
        id: 2
      },
      {
        type: 'field',
        id: 3
      },
      {
        type: 'field',
        id: 4
      },
      {
        type: 'field',
        id: 5
      },
      {
        type: 'title',
        content: {
          fi: 'Valintapainikkeita'
        }
      },
      {
        type: 'field',
        id: 6
      },
      {
        type: 'field',
        id: 7
      },
      {
        type: 'field',
        id: 8,
        filter: {
          show: {
            target: 4,
            includesEvery: [1, 2]
          }
        }
      },
      {
        type: 'field',
        id: 1,
        filter: {
          enable: {
            target: 0,
            equals: 'asd'
          }
        }
      },
      {
        type: 'body',
        content: {
          fi: 'leipä*tekstiä*'
        }
      },
      {
        type: 'formgroup',
        id: 0
      },
      {
        type: 'field',
        id: 9
      }
    ]
  },
  {
    [FIELD.TITLE]: {
      fi: 'Esimerkkilomake 2',
      en: 'Example form 2'
    },
    [FIELD.ELEMENTS]: [
      {
        type: 'pageTitle',
        content: {
          fi: 'Ensimmäinen sivu'
        }
      },
      {
        type: 'field',
        id: 0,
        required: true
      },
      {
        type: 'pageTitle',
        content: {
          fi: 'Toinen sivu'
        }
      },
      {
        type: 'field',
        id: 5
      },
      {
        type: 'pageTitle',
        content: {
          fi: 'Kolmas sivu'
        }
      },
      {
        type: 'field',
        id: 0,
        required: true
      }
    ]
  },
  {
    [FIELD.TITLE]: {
      fi: 'Esimerkkilomake 3',
      en: 'Example form 3'
    },
    [FIELD.ELEMENTS]: [
      {
        type: 'field',
        id: 1
      },
      {
        type: 'formgroup',
        id: 2
      }
    ],
    [FIELD.SUCCESS_TEXT]: {
      fi: 'Onneksi olkoon!',
      en: 'Congratulations!'
    }
  }
];
