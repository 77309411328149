import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Value } from 'react-powerplug';
import connect from '../../../../../utils/react-refetch/connectAndRenderOnFulfilled';
import withLocalize from '../../../../utils/withLocalize';
import messages from './intl/messages.properties';
import AssignModal from './AssignModal';
import AssignModalProfessional from './AssignModalProfessional';

export default connect(({ onAssign, type }) => ({
  formsFetch: `/api/form/type/${type || 0}`,
  assign: ({ 0: id, 1: dateOfExpiry, 2: info }) => ({
    assignResponse: {
      url: `/api/form/${id}/assign`,
      method: 'PUT',
      data: { dateOfExpiry, info },
      then: value => do {
        onAssign();
        ({ value });
      }
    }
  })
}))(
  (({ assign, formsFetch, localize, buttonText, type }) => (
    <Value>
      {({ value, set }) => (
        <>
          <Button bsStyle="primary" onClick={() => set(true)}>
            <FormattedMessage id={buttonText ? buttonText : messages.assign} />
          </Button>

          {type === 0 && (
            <AssignModal
              assign={assign}
              formsFetch={formsFetch}
              localize={localize}
              value={value}
              set={set}
            />
          )}

          {type === 1 && (
            <AssignModalProfessional
              assign={assign}
              formsFetch={formsFetch}
              localize={localize}
              value={value}
              set={set}
            />
          )}
        </>
      )}
    </Value>
  )) |> withLocalize
);
