import { FIELD } from '../../../formGroups';

export default [
  {
    [FIELD.TITLE]: {
      fi: 'Esimerkkikysymysryhmä',
      en: 'Example form group'
    },
    [FIELD.NOTES]:
      'Tässä tärkeitä muistiinpanoja koskien esimerkkikysymysryhmää.',
    [FIELD.ELEMENTS]: [
      {
        type: 'field',
        id: 0,
        required: true
      },
      {
        type: 'field',
        id: 1,
        filter: {
          show: {
            target: 0,
            equals: 'asd'
          }
        }
      },
      {
        type: 'field',
        id: 0
      },
      {
        type: 'field',
        id: 3
      },
      {
        type: 'body',
        content: {
          fi: 'leipä*tekstiä*'
        }
      }
    ]
  },
  {
    [FIELD.TITLE]: {
      fi: 'Kysymysryhmä B',
      en: 'Form group B'
    },
    [FIELD.NOTES]:
      'Tässä tärkeitä muistiinpanoja koskien esimerkkikysymysryhmää.',
    [FIELD.ELEMENTS]: [
      {
        type: 'field',
        id: 0
      },
      {
        type: 'field',
        id: 1,
        filter: {
          show: {
            target: 0,
            equals: 'asd'
          }
        }
      }
    ]
  },
  {
    [FIELD.TITLE]: {
      fi: 'Toistettava kysymysryhmä'
    },
    [FIELD.ELEMENTS]: [
      {
        type: 'field',
        id: 0
      },
      {
        type: 'field',
        id: 5
      }
    ],
    [FIELD.LIST]: true
  }
];
