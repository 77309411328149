import { ListGroup } from 'react-bootstrap';
import connect from '../../../utils/react-refetch/connectAndRenderOnFulfilled';
import ListGroupItem from './ListGroupItem';

export default connect(() => ({
  dataFetch: '/api/formdata',
  formFetch: '/api/form'
}))(({ dataFetch, formFetch }) => (
  <ListGroup>
    {dataFetch.sort((a, b) => a.timestamp < b.timestamp ).map((item, index) => (
      <ListGroupItem key={index} item={item} forms={formFetch} />
    ))}
  </ListGroup>
));
