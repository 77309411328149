import {FormattedDate} from "react-intl";

export default (({ time, status }) => (
  time ? <FormattedDate
        value={time}
        day="numeric"
        month="numeric"
        year="numeric"
        hour="numeric"
        minute="numeric"
      />
    : null
))
