import { injectIntl } from 'react-intl';
import { FormGroup, ListGroup } from 'react-bootstrap';
import { FIELD } from '../../../formGroups';
import connect from '../../../utils/react-refetch/connectAndRenderOnFulfilled';
import ButtonNew from '../../ButtonNew';
import Filter from '../../Filter';
import ListGroupItem from '../../lists/ListGroupItem';
import Header from '../../lists/ListGroupItem/Header';
import { getLanguage } from '../../utils/withLocalize';
import FieldCount from '../../lists/FieldCount';
import UsageCount from '../../lists/UsageCount';
import messages from './intl/messages.properties';

export default connect(() => ({
  formGroupsFetch: '/api/formgroup',
  formsFetch: '/api/form'
}))(
  (({ formGroupsFetch, formsFetch, intl: { locale } }) => (
    <>
      <FormGroup>
        <ButtonNew id={messages.newFormgroup} />
      </FormGroup>
      <Filter items={formGroupsFetch} path={[FIELD.TITLE, getLanguage(locale)]}>
        {({ items, searchWords }) => (
          <ListGroup>
            {items.sort((a,b) => a[FIELD.TITLE][locale.slice(0,2).toLowerCase()] > b[FIELD.TITLE][locale.slice(0,2).toLowerCase()]).map(item => (
              <ListGroupItem
                key={item._id}
                item={item}
                header={
                  <>
                    <Header
                      name={item[FIELD.TITLE]}
                      searchWords={searchWords}
                    />{' '}
                    <FieldCount elements={item[FIELD.ELEMENTS]} />{' '}
                    <UsageCount formsFetch={formsFetch} element={item} />
                  </>
                }
              >
                {item[FIELD.NOTES]}
              </ListGroupItem>
            ))}
          </ListGroup>
        )}
      </Filter>
    </>
  )) |> injectIntl
);
