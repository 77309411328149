import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

export default (({ id, match: { path } }) => (
  <LinkContainer to={`${path}/new`}>
    <Button bsStyle="primary">
      <FormattedMessage id={id} />
    </Button>
  </LinkContainer>
)) |> withRouter;
