import { Component } from 'react';
import { FormControl, FormGroup, Button, InputGroup } from 'react-bootstrap';
import BG from './BG.png';

export default class THX extends Component {
  static defaultProps = {
    labels: ['a','b','c','d','e'],
  };
  state = {};
  static getDerivedStateFromProps = ({value,labels}, state) => {

    let keys = state?.keys || {
      'a': false,
      'b': false,
      'c': false,
      'd': false,
      'e': false
    }

    if (!state?.keys && value) {
      const json = JSON.parse(value);
      for (let i in json) {
        keys[json[i][0]] = i;
      }
    }

    return {
      value: state?.value || (value && JSON.parse(value)) || [],
      keys: keys,
      labels
    };
  }

  handleClick = value => {
    if (this.props.disabled) return;

    const newState = {...this.state};
    if (newState.keys[value] === false) {
      newState.keys[value] = newState.value.push([
        value, ''
      ])-1;
    }
    this.setState(newState);
    this.props.onChange(JSON.stringify(newState.value));
  }

  handleValue = (index, event) => {
    const newState = {...this.state};
    newState.value[index][1] = event.target.value;
    this.setState(newState);
    this.props.onChange(JSON.stringify(newState.value));
  }

  removeItem = index => {
    if (this.props.disabled) return;

    const newState = {...this.state};
    newState.value = newState.value.filter((v,i) => {
      if (i === index) {
        newState.keys[v[0]] = false;
      }
      return i !== index;
    }).map((v, i) => {
      newState.keys[v[0]] = i;
      return v;
    });

    this.setState(newState);
    this.props.onChange(JSON.stringify(newState.value));
  }

  renderButton = (item, className) => {
    return (
      <div className={`col-md-4 ${className || ''}`} key={item}>
        <Button variant="secondary" size="sm" onClick={() => this.handleClick(item)} disabled={this.props.disabled}>
          {
            this.state.keys[item] !== false ? this.props.labels[this.state.keys[item]] : '.'
          }
        </Button>
      </div>
    );
  }

  render() {
    const {disabled} = this.props;
    return (
      <FormGroup className="thx">
        <div className="inputs">
          {
            this.state.value.map((item, index) => (
              <InputGroup className="mb-3" key={index}>
                <InputGroup.Addon>{this.props.labels[this.state.keys[item[0]]]}</InputGroup.Addon>
                <FormControl onChange={event => this.handleValue(index, event)} value={item[1]} disabled={disabled}/>
                <InputGroup.Button hidden={disabled}>
                  <Button variant="outline-secondary" onClick={() => this.removeItem(index)}>X</Button>
                </InputGroup.Button>
              </InputGroup>
            ))
          }
        </div>
        <div className="THXgroup">
          <img src={BG} alt="THX"/>
          <div className="buttons">
            <div className="row">
              {
                Object.keys(this.state.keys).filter((v,i) => i < 2).map((item,i) => this.renderButton(item, i === 1 ? 'col-md-offset-4' : undefined))
              }
            </div>
            <div className="row">
              {
                Object.keys(this.state.keys).filter((v,i) => i === 2).map((item,i) => this.renderButton(item, 'col-md-offset-4'))
              }
            </div>
            <div className="row">
              {
                Object.keys(this.state.keys).filter((v,i) => i > 2).map((item,i) => this.renderButton(item, i === 1 ? 'col-md-offset-4' : undefined))
              }
            </div>
          </div>
        </div>
      </FormGroup>
    );
  }
}
