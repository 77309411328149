import FormULA from '@visma/form-ula';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Value } from 'react-powerplug';
import elements from '../../../forms';
import SubmitWasSuccessful from '../../SubmitWasSuccessful';
import Editor from '../../Editor';
import filterIncompleteElements from '../../Editor/filterIncompleteElements';
import { FIELD } from '../../Editor/fields';
import withFields from '../Fields/withFields';
import withFormGroups from '../FormGroups/withFormGroups';
import messages from '../../intl/messages.properties';
import {CustomFieldsSchema, CustomFieldsUISchema} from "../../CustomFields";

export default (({ fields, formGroups, localize, ...props }) => (
  <Editor
    {...props}
    design={{
      [FIELD.TITLE]: { fi: 'Kysely' },
      [FIELD.ELEMENTS]: elements
    }}
    Preview={({ formData }) => (
      <Value>
        {({ value, set }) =>
          value ? (
            <SubmitWasSuccessful
              design={formData}
              values={value}
              OtherButtons={() => (
                <Button bsStyle="warning" onClick={() => set()}>
                  <FormattedMessage id={messages.back} />
                </Button>
              )}
            />
          ) : (
            <FormULA
              design={{
                ...formData,
                [FIELD.ELEMENTS]: filterIncompleteElements(
                  formData?.[FIELD.ELEMENTS]
                )
              }}
              fields={fields}
              formGroups={formGroups}
              onSubmit={({ formData }) => set(formData)}
              submitButtonMessageId={messages.submit}
              extraFieldTypes={{
                schema : {...CustomFieldsSchema},
                uiSchema: () => ({...CustomFieldsUISchema})
              }}
            />
          )
        }
      </Value>
    )}
  />
))
  |> withFields
  |> withFormGroups;
