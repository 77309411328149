import LimitedDateEdit from './LimitedDateEdit.js';
import LimitedDateRead from './LimitedDateRead.js';

export const Read = LimitedDateRead;
export const Type = 201;
export const uiSchema = {
  'ui:widget': LimitedDateEdit
};
export const schema = {
  format: 'date',
  type: 'string'
};

export const type = { enum: Type, enumNames: { fi: 'Rajoitettu Päivämäärä' } };
