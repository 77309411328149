import connect from '../utils/react-refetch/connectAndRenderOnFulfilled';
import { Grid, Row, Col } from "react-bootstrap";

export default connect(() => ({
  patient: '/api/patient'
}))(
  (({ patient: { name, nationalId, phoneNumber} }) => (

    <div className="patientInfoWrapper">
      <Grid bsprefix="patientInfo">
        <Row>
          <Col md={12}><b>{name}</b></Col>
        </Row>
        <Row>
          <Col md={12}><b>{nationalId}</b></Col>
        </Row>
        <Row>
          <Col md={12}><b>{phoneNumber}</b></Col>
        </Row>
      </Grid>
    </div>

  ))
);
