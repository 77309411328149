import { FIELD } from '../../../../fields';

export default ({
  [String(FIELD.LIST)]: list,
  [String(FIELD.TYPE)]: type,
  [String(FIELD.NAME)]: name,
  [String(FIELD.USE_LABEL)]: useLabel,
  [String(FIELD.LABEL)]: label,
  [String(FIELD.DESCRIPTION)]: description,
  [String(FIELD.HELP)]: help,
  [String(FIELD.NOTES)]: notes,
  [String(FIELD.SELECT_WIDGET)]: selectWidget,
  [String(FIELD.MULTISELECT_WIDGET)]: multiselectWidget,
  [String(FIELD.CHOICES)]: choices,
  [String(FIELD.PATTERN)]: pattern,
  [String(FIELD.PATTERN_DESCRIPTION)]: patternDescription,
  ...rest
}) => ({
  ...rest,
  list,
  type,
  name,
  useLabel,
  label,
  description,
  help,
  notes,
  selectWidget,
  multiselectWidget,
  choices,
  pattern,
  patternDescription
});
