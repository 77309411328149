import { Component } from 'react';
import withInstances from './withInstances';

export default withInstances(
  class LocalStorage extends Component {
    static getNextState = value =>
      value === null
        ? { exists: false }
        : { exists: true, value: JSON.parse(value) };

    state = LocalStorage.getNextState(localStorage.getItem(this.props.itemKey));

    componentDidMount() {
      window.addEventListener('storage', this.handleStorageEvent);
    }

    componentWillUnmount() {
      window.removeEventListener('storage', this.handleStorageEvent);
    }

    setStateForEachInstanceWithSameKey(state) {
      return [...LocalStorage.instances]
        .filter(({ props: { itemKey } }) => itemKey === this.props.itemKey)
        .forEach(instance => instance.setState(state));
    }

    handleRemove = () => {
      localStorage.removeItem(this.props.itemKey);
      this.setStateForEachInstanceWithSameKey({ exists: false });
    };

    handleSet = value => {
      localStorage.setItem(this.props.itemKey, JSON.stringify(value));
      this.setStateForEachInstanceWithSameKey({ exists: true, value });
    };

    handleStorageEvent = ({ key, newValue, storageArea }) => {
      if (key === this.props.itemKey && storageArea === localStorage) {
        this.setState(LocalStorage.getNextState(newValue));
      }
    };

    render() {
      const {
        props: { children },
        state: { exists, value }
      } = this;
      return children({
        value: exists ? value : undefined,
        set: this.handleSet,
        remove: this.handleRemove
      });
    }
  }
);
