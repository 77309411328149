import React from 'react';
import List from './List';
import connect from '../../../utils/react-refetch/connectAndRenderOnFulfilled';
import moment from 'moment';
import { zipWith, partition } from 'lodash';
import messages from './intl/messages.properties';
import { Fragment } from 'react';

export default connect(() => ({
    patientEventsFetch: '/api/patient/events'
  }))(({ patientEventsFetch }) => (
  <Fragment>
    {/* Partitions patient events to upcoming and past events and renders lists for them */}
    {zipWith(
      partition(patientEventsFetch, item => moment(item.date).isAfter(moment().startOf('day'))),
      [messages.upcoming, messages.past],
      (items, title) =>
        <List key={title} items={items} title={title} />
    )}
  </Fragment>
));
