import React, { Component } from 'react';
import moment from 'moment';

class LimitedDateRead extends Component {
  render() {
    const { value } = this.props;

    return (
      <>
        {moment(value).format('D.M.YYYY')}
      </>
    );
  }
}

export default LimitedDateRead;
