import { Component } from 'react';
import connect from '../../utils/react-refetch/connectAndRenderOnFulfilled';

export default connect(({ apiPath, match: { params: { id } } }) => ({
  fetch: {
    url: `/api/${apiPath}/${id}`
  },
  post: formData => ({
    postResponse: {
      url: `/api/${apiPath}`,
      method: 'PUT',
      data: formData,
      force: true,
      andThen: () => ({
        fetch: {
          url: `/api/${apiPath}/${id}`,
          force: true,
          refreshing: true
        }
      })
    }
  })
}))(
  class Edit extends Component {
    state = {};

    static getDerivedStateFromProps({ fetch }, { formData }) {
      return fetch._rev !== formData?._rev ? { formData: fetch } : null;
    }

    post = ({ formData }) => {
      this.setState({ formData });
      this.props.post(formData);
    };

    render() {
      const {
        post,
        props: { Editor },
        state: { formData }
      } = this;
      return <Editor formData={formData} onSubmit={post} />;
    }
  }
);
