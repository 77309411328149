import { createRef } from 'react';
import { ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import PatientInfo from '../../../PatientInfo';
import Assign from './Assign';
import List from './List';
import messages from './Assign/intl/messages.properties';

export default () => {
  const list = createRef();
  return (
    <>
      <PatientInfo />
      <h2>Potilaan Lomakkeet</h2>
      <div className="pull-right">
        <ButtonToolbar>
          <ButtonGroup>​{/* Zero width space. */}</ButtonGroup>
          <ButtonGroup>
            <Assign onAssign={() => list.current.refreshForms()} type={0} />
          </ButtonGroup>
        </ButtonToolbar>
      </div>
      <List refValue={list} />
      <h2>Ammattilaisen Lomakkeet</h2>
      <div className="pull-right">
        <ButtonToolbar>
          <ButtonGroup>​{/* Zero width space. */}</ButtonGroup>
          <ButtonGroup>
            <Assign onAssign={() => list.current.refreshForms()} type={1} buttonText={messages.add} />
          </ButtonGroup>
        </ButtonToolbar>
      </div>
      <List refValue={list} type={"professional"} />
    </>
  );
};
