import { Route } from 'react-router-dom';
import { Grid } from 'react-bootstrap';
import { Role, PROFESSIONAL } from '../../Role';
import EventGroups from './EventGroups';
import PatientInfo from '../../PatientInfo';

export default ({ match: { path } }) => (
  <>
    <Grid>
      <Role is={PROFESSIONAL}>
        <PatientInfo />
        <Route exact path={path} component={EventGroups} />
      </Role>
    </Grid>
  </>
);
