import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FormGroup } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import { Role, PROFESSIONAL } from '../../Role';
import PatientInfo from '../../PatientInfo';
import { withToken } from '../../App/Authorize';
import View from '../../../api/View';
import connect from '../../../utils/react-refetch/connectAndRenderOnFulfilled';
import ObjectFieldTemplate from './Anestesia';
import ButtonPrint from '../../ButtonPrint';
import messages from '../../intl/messages.properties';
import './FillStyle.scss';

const Fill = connect(({ id, dataId }) => ({
  formAssignment: `/api/assigned/professional/${id}`
}))(
  class Fill extends Component {
    componentDidMount = () => {
      const {
        props: { formAssignment },
        handleClick
      } = this;

      if (formAssignment && formAssignment[0].fi === 'Anestesia Esilomake') {
        window.addEventListener("mousedown", handleClick);
      }
    }

    componentWillUnmount = () => {
      const {
        props: { formAssignment },
        handleClick
      } = this;

      if (formAssignment && formAssignment[0].fi === 'Anestesia Esilomake') {
        window.removeEventListener("mousedown", handleClick);
      }
    }

    handleClick = e => {
      const { history, formatMessage } = this.props;
      const linkWasClicked = e.target.nodeName === 'A';
      const linkTextWasClicked = e.target.parentNode.nodeName === "A";

      if (linkWasClicked || linkTextWasClicked) {
        let userWantsToLeave = window.confirm(
          formatMessage({id: messages.doYouWantToLeave})
        );
        let targetPath;

        if (linkWasClicked) {
          targetPath = e.target.attributes.href.value;
        } else if (linkTextWasClicked) {
          targetPath = e.target.parentNode.attributes.href.value;
        }

        if (userWantsToLeave && targetPath) {
          history.push(targetPath);
        }
      }
    }

    render() {
      const {
        props: {
          id,
          token,
          locale,
          formAssignment
        }
      } = this;

      return (
        <>
          <Role is={PROFESSIONAL}>
            <PatientInfo />
          </Role>
          <FormGroup className="FormFill">
            <View
              authToken={token}
              dataId={formAssignment?.dataId}
              id={id}
              formId={formAssignment?.formId}
              userId={formAssignment?.userId}
              locale={locale}
              onSubmitRedirectTo={formAssignment?.dataId ? `/values/${formAssignment?.dataId}/view` : '/values'}
              showPreview={false}
              ObjectFieldTemplate={ObjectFieldTemplate}
              professional={true}
            />
            <ButtonPrint className="ButtonPrint"/>
          </FormGroup>
        </>
      );
    }
  }
)

export default (({
  intl: { formatMessage, locale },
  match: {
    params: { id, dataId }
  },
  token,
  history
}) => (
  <Fill locale={locale} id={id} dataId={dataId} token={token} history={history} formatMessage={formatMessage} />
))
  |> withToken
  |> injectIntl
  |> withRouter;
