import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import ArrayField from 'react-jsonschema-form/lib/components/fields/ArrayField';
import { range } from 'lodash';
import Confirm from '../utils/Confirm';
import messages from '../intl/messages.properties';

const { min, max } = Math;
const getTarget = item => item?.filter?.show?.target;

export const isFieldReferencedBySome = ({ formData, index }) =>
  formData.map(getTarget).some(target => index === target);

export default class MaintainRuleIndicesArrayField extends ArrayField {
  state = {};

  constructor(props) {
    super(props);
    const { onDropIndexClick, onReorderClick } = this;
    this._super_onDropIndexClick = onDropIndexClick;
    this.onDropIndexClick = index => event => {
      const { formData } = this.props;
      if (isFieldReferencedBySome({ formData, index })) {
        if (event) {
          event.preventDefault();
        }
        this.setState({ showRemoveConfirmModalForIndex: index });
      } else {
        this.fixIndexes(index);
        this._super_onDropIndexClick(index)(event);
      }
    };
    this.onReorderClick = (index, newIndex) => event => {
      const affectedRange = range(
        min(index, newIndex),
        max(index, newIndex) + 1
      );
      const { formData } = this.props;
      formData.forEach(item => {
        const target = item.filter?.show?.target;
        if (affectedRange.includes(target)) {
          item.filter.show.target =
            index === target
              ? newIndex
              : index < newIndex
                ? target - 1
                : target + 1;
        }
      });
      onReorderClick(index, newIndex)(event);
    };
  }

  fixIndexes(removedIndex) {
    const { formData } = this.props;
    formData.forEach(item => {
      const target = getTarget(item);
      if (removedIndex < target) {
        item.filter.show.target = target - 1;
      }
    });
  }

  handleClose = () => this.setState({ showRemoveConfirmModalForIndex: null });

  handleRemoveReferencesAndDropIndex = () => {
    const {
      props: { formData },
      state: { showRemoveConfirmModalForIndex: index }
    } = this;
    formData.forEach(item => {
      const target = item.filter?.show?.target;
      if (index === target) {
        delete item.filter;
      }
    });
    this.fixIndexes(index);
    this.handleClose();
    this._super_onDropIndexClick(index)();
  };

  render() {
    return (
      <>
        <Confirm
          show={typeof this.state.showRemoveConfirmModalForIndex === 'number'}
          onClose={this.handleClose}
          onConfirm={this.handleRemoveReferencesAndDropIndex}
          bsStyle="danger"
          action={<FormattedMessage id={messages.remove} />}
        >
          <FormattedHTMLMessage id={messages.confirmRemoveFilter} />
        </Confirm>
        {super.render()}
      </>
    );
  }
}
