import { FIELD } from '../../../../forms';

export default ({
  [String(FIELD.ELEMENTS)]: elements,
  [String(FIELD.NOTES)]: notes,
  [String(FIELD.SUCCESS_TEXT)]: successText,
  [String(FIELD.TITLE)]: title,
  [String(FIELD.FORM_TYPE)]: type,
  [String(FIELD.LOGO)]: logo,
  ...rest
}) => ({
  ...rest,
  elements,
  notes,
  successText,
  title,
  type,
  logo
});
