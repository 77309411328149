import { injectIntl } from 'react-intl';
import { MenuItem, NavDropdown } from 'react-bootstrap';
import { Selector } from '@visma/react-intl-helpers';
import messages from '../../intl/messages.properties';

const createLink = children => ({ href, key }) => (
  <MenuItem key={key} eventKey={key} href={href} disabled={!href}>
    {children}
  </MenuItem>
);

export default injectIntl(({ intl: { formatMessage } }) => (
  <NavDropdown
    eventKey={4}
    title={formatMessage({ id: messages.language })}
    id="nav-dropdown-menu"
  >
    <Selector
      locales={[
        {
          locale: 'fi-FI',
          children: createLink('Suomi')
        },
        {
          locale: 'en-US',
          children: createLink('English')
        }
      ]}
    />
  </NavDropdown>
));
