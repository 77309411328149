import { withRouter } from 'react-router-dom';
import { ListGroupItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import FormattedDatetime from '../../../utils/FormattedDatetime';
import FormName from '../FormName';
import UserInfo from './UserInfo';

export default (({ match: { path }, item: { _id, form, timestamp, user }, forms }) => (
  <LinkContainer to={`${path}/${_id}/view`}>
    <ListGroupItem>
      <FormName form={forms.find(f => f._id === form.id)}/>
      <UserInfo user={user}/>{' '}
      <small>
        <FormattedDatetime value={timestamp}/>
      </small>
    </ListGroupItem>
  </LinkContainer>
)) |> withRouter;
