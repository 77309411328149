export const back = '../../src/components/intl:back';
export const cancel = '../../src/components/intl:cancel';
export const clear = '../../src/components/intl:clear';
export const close = '../../src/components/intl:close';
export const confirmRemoveFilter = '../../src/components/intl:confirmRemoveFilter';
export const edit = '../../src/components/intl:edit';
export const filter = '../../src/components/intl:filter';
export const form = '../../src/components/intl:form';
export const forms = '../../src/components/intl:forms';
export const formgroups = '../../src/components/intl:formgroups';
export const videoAppointments = '../../src/components/intl:videoAppointments';
export const print = '../../src/components/intl:print';
export const usageCount = '../../src/components/intl:usageCount';
export const fieldCount = '../../src/components/intl:fieldCount';
export const language = '../../src/components/intl:language';
export const newForm = '../../src/components/intl:newForm';
export const noName = '../../src/components/intl:noName';
export const noNationalId = '../../src/components/intl:noNationalId';
export const note = '../../src/components/intl:note';
export const noteHelp = '../../src/components/intl:noteHelp';
export const ok = '../../src/components/intl:ok';
export const patientInfoTitle = '../../src/components/intl:patientInfoTitle';
export const patientInfoName = '../../src/components/intl:patientInfoName';
export const patientInfoNationalId = '../../src/components/intl:patientInfoNationalId';
export const patientInfoPhonenumber = '../../src/components/intl:patientInfoPhonenumber';
export const fields = '../../src/components/intl:fields';
export const remove = '../../src/components/intl:remove';
export const save = '../../src/components/intl:save';
export const submit = '../../src/components/intl:submit';
export const archive = '../../src/components/intl:archive';
export const submitModalTitle = '../../src/components/intl:submitModalTitle';
export const submitModalBody = '../../src/components/intl:submitModalBody';
export const archiveModalTitle = '../../src/components/intl:archiveModalTitle';
export const archiveModalBody = '../../src/components/intl:archiveModalBody';
export const values = '../../src/components/intl:values';
export const contextNotFound = '../../src/components/intl:contextNotFound';
export const frontpage = '../../src/components/intl:frontpage';
export const doYouWantToLeave = '../../src/components/intl:doYouWantToLeave';
export const date = '../../src/components/intl:date';
export default {back, cancel, clear, close, confirmRemoveFilter, edit, filter, form, forms, formgroups, videoAppointments, print, usageCount, fieldCount, language, newForm, noName, noNationalId, note, noteHelp, ok, patientInfoTitle, patientInfoName, patientInfoNationalId, patientInfoPhonenumber, fields, remove, save, submit, archive, submitModalTitle, submitModalBody, archiveModalTitle, archiveModalBody, values, contextNotFound, frontpage, doYouWantToLeave, date};
