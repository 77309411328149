import { mapValues } from 'lodash';
import data from './data';
import field from './field';
import form from './form';
import formGroup from './formGroup';
import getURLPath from '../../getURLPath';

export default connect => propsToOptions => Component =>
  connect(propsToOptions)(props => (
    <Component
      {...props}
      {...mapValues(propsToOptions(props), (option, name) =>
        typeof option === 'function'
          ? props[name]
          : do {
              const url = getURLPath(
                typeof option === 'object' ? option.url : option
              );
              const { value } = props[name];
              ({
                ...props[name],
                value:
                  value &&
                  do {
                    const endpoint = [
                      { path: 'assigned', method: form },
                      { path: 'field', method: field },
                      { path: 'formdata', method: data },
                      { path: 'formgroup', method: formGroup },
                      { path: 'form', method: form }
                    ].find(({ path }) => url.startsWith(`/api/${path}`));
                    endpoint
                      ? do {
                          const { method } = endpoint;
                          value.length >= 0
                            ? value.map(method)
                            : method(value);
                        }
                      : value;
                  }
              });
            }
      )}
    />
  ));
