import { FormattedMessage, injectIntl } from 'react-intl';
import { datetime } from './intl/messages.properties';

/*
Example:
    <FormattedDatetime
        value={Date.now()}
        dateOptions={{ month: 'long' }}
    />
    --> "8. maaliskuuta 2018 klo 14.49"
*/

export default (({
  intl: { formatDate, formatTime },
  value,
  dateOptions,
  timeOptions
}) => (
  <FormattedMessage
    id={datetime}
    values={{
      date: formatDate(value, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        ...dateOptions
      }),
      time: formatTime(value, timeOptions)
    }}
  />
)) |> injectIntl;
