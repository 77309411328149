import Highlighter from 'react-highlight-words';
import withLocalize from '../../utils/withLocalize';
import { highlight } from './styles.module.scss';

export default (({ localize, name, searchWords }) => (
  <Highlighter
    highlightClassName={highlight}
    searchWords={searchWords}
    autoEscape
    textToHighlight={localize(name)}
  />
)) |> withLocalize;
