import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClipboardList,
  faList,
  faQuestion
} from '@fortawesome/free-solid-svg-icons';
import messages from '../../intl/messages.properties';

export default () => (
  <Row style={{ textAlign: 'center' }}>
    {[
      { id: 'forms', icon: faClipboardList },
      { id: 'formgroups', icon: faList },
      { id: 'fields', icon: faQuestion }
    ].map(({ id, icon }) => (
      <Link key={id} to={`/${id}`}>
        <Col xs={4}>
          <FontAwesomeIcon icon={icon} size="6x" />
          <br />
          <br />
          <FormattedMessage id={messages[id]} />
        </Col>
      </Link>
    ))}
  </Row>
);
