import { Route } from 'react-router-dom';
import { Grid } from 'react-bootstrap';
import Create from './Create';
import Edit from './Edit';
import List from './List';

export default ({ match: { path } }) => (
  <>
    <Grid>
      <Route exact path={path} component={List} />
    </Grid>
    <Route path={`${path}/new`} component={Create} />
    <Route path={`${path}/:id/edit`} component={Edit} />
  </>
);
