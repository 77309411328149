export const fill = '../../src/components/Pages/Home/Professional/List/intl:fill';
export const show = '../../src/components/Pages/Home/Professional/List/intl:show';
export const assigned = '../../src/components/Pages/Home/Professional/List/intl:assigned';
export const dateOfExpiry = '../../src/components/Pages/Home/Professional/List/intl:dateOfExpiry';
export const filled = '../../src/components/Pages/Home/Professional/List/intl:filled';
export const status = '../../src/components/Pages/Home/Professional/List/intl:status';
export const form = '../../src/components/Pages/Home/Professional/List/intl:form';
export const additionalInfo = '../../src/components/Pages/Home/Professional/List/intl:additionalInfo';
export const functions = '../../src/components/Pages/Home/Professional/List/intl:functions';
export default {fill, show, assigned, dateOfExpiry, filled, status, form, additionalInfo, functions};
