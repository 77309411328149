import { FormattedMessage } from 'react-intl';
import messages from './intl/messages.properties';

export default ({ children }) => (
  <>
    <h1>
      <FormattedMessage id={messages.preview} />
    </h1>
    {children}
  </>
);
