import React, { useImperativeHandle } from 'react';
import { injectIntl, FormattedMessage, FormattedDate } from 'react-intl';
import { ListGroup, Table } from 'react-bootstrap';
import { FIELD } from '../../../../../forms';
import connect from '../../../../../utils/react-refetch/connectAndRenderOnFulfilled';
import Filter from '../../../../Filter';
import Timestamp from '../../../../lists/Timestamp'
import Header from '../../../../lists/ListGroupItem/Header';
import { getLanguage } from '../../../../utils/withLocalize';
import Status from './Status';
import ButtonFill from './ButtonFill';
import ButtonShow from './ButtonShow';
import messages from "./intl/messages.properties";

export default connect(({type}) => ({
  forms: `/api/assigned${type ? `/${type}` : ''}`,
  refreshForms: () => ({
    forms: {
      url: `/api/assigned${type ? `/${type}` : ''}`,
      force: true,
      refreshing: true
    }
  })
}))(
  (({ forms, intl: { locale }, refValue, refreshForms, type }) => {
    useImperativeHandle(refValue, () => ({
      refreshForms
    }));
    return (
      <Filter items={forms} path={[FIELD.TITLE, getLanguage(locale)]} type={type || 'patient'}>
        {({ items, searchWords }) => (
          <ListGroup className="home">
            <Table responsive>
              <thead>
                <tr>
                  <th className="timestamp">
                    <FormattedMessage id={messages.assigned}/>
                  </th>

                  <th className="timestamp">
                    <FormattedMessage id={messages.dateOfExpiry}/>
                  </th>

                  <th className="timestamp">
                    <FormattedMessage id={messages.filled}/>
                  </th>

                  <th className="status">
                    <FormattedMessage id={messages.status}/>
                  </th>

                  <th className="header">
                    <FormattedMessage id={messages.form}/>
                  </th>

                  <th className="additional-info">
                    <FormattedMessage id={messages.additionalInfo}/>
                  </th>

                  <th className="action-container">
                    <FormattedMessage id={messages.functions}/>
                  </th>

                </tr>
              </thead>

              <tbody>
                {items.sort((a, b) => b.assignedTimestamp - a.assignedTimestamp).map(item => (
                  <tr className="list-group-item" key={item.dataId}>
                    <td className="timestamp">
                      <Timestamp time={item.assignedTimestamp} status={item.status}/>
                    </td>

                    <td className="timestamp">
                      {item.dateOfExpiry && <FormattedDate
                        value={item.dateOfExpiry}
                        day="numeric"
                        month="numeric"
                        year="numeric"
                      />}
                    </td>

                    <td className="timestamp">
                      <Timestamp time={item.modifyTimestamp} status={item.status}/>
                    </td>

                    <td className="status">
                        <Status status={item.status} />
                    </td>

                    <td className="header">
                      <Header
                        name={item[FIELD.TITLE]}
                        searchWords={searchWords}
                      />
                    </td>

                    <td className="additional-info">
                      {item.info}
                    </td>

                    <td className="action-container">
                      {
                        (item.status === 'SUBMITTED' || type !== 'professional')
                          ? <ButtonShow form={item}/>
                          : <ButtonFill form={item} />
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ListGroup>
        )}
      </Filter>
    );
  }) |> injectIntl
);
