import { Fragment } from 'react';
import { FormGroup, ListGroup, ListGroupItem, Panel } from 'react-bootstrap';
import { FIELD } from '../../../../../../fields';
import withLocalize from '../../../../../utils/withLocalize';
import { Consumer, DISPLAY_FORMAT } from '../../ChooseDisplayFormat';
import Type from './Type';

export default (({
  value,
  field,
  field: { [FIELD.LIST]: list, [FIELD.NAME]: name },
  localize,
  includeCondensed
}) => (
  <Consumer>
    {displayFormat => do {
      const title = localize(name);
      const type = <Type field={field} value={value} />;
      ({
        [DISPLAY_FORMAT.CONDENSED]: () => {
          if (!includeCondensed) {
            return null;
          }
          return list ? (
            value?.map((valueEntry, index) => (
              <tr key={index}>
                {index === 0 ? <td rowSpan={value.length}>{title}</td> : null}
                <td>
                  <Type value={valueEntry} field={field} />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td>{title}</td>
              <td>{type}</td>
            </tr>
          )},
        [DISPLAY_FORMAT.LIST]: () => (
          <>
            <strong>{title}</strong>
            {list ? (
              value?.map((value, index) => (
                <Fragment key={index}>
                  {index ? <hr /> : null}
                  <FormGroup key={index}>
                    <Type value={value} field={field} />
                  </FormGroup>
                </Fragment>
              ))
            ) : (
              <FormGroup>{type}</FormGroup>
            )}
          </>
        ),
        [DISPLAY_FORMAT.PANEL]: () => (
          <Panel>
            <Panel.Heading>{title}</Panel.Heading>
            {list ? (
              <ListGroup>
                {value?.map((value, index) => (
                  <ListGroupItem key={index}>
                    <Type value={value} field={field} />
                  </ListGroupItem>
                ))}
              </ListGroup>
            ) : (
              <Panel.Body>{type}</Panel.Body>
            )}
          </Panel>
        ),
        [DISPLAY_FORMAT.TABLE]: () =>
          list ? (
            value?.map((valueEntry, index) => (
              <tr key={index}>
                {index ? null : <td rowSpan={value.length}>{title}</td>}
                <td>
                  <Type value={valueEntry} field={field} />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td>{title}</td>
              <td>{type}</td>
            </tr>
          )
      }[displayFormat]());
    }}
  </Consumer>
)) |> withLocalize;
