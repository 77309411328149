import { Fragment } from 'react';
import { pickBy } from 'lodash';
import filter from '@visma/form-ula/es/filter';
import * as TYPE from '@visma/form-ula/es/constants/elementTypes';
import {
  FormGroup,
  ListGroup,
  ListGroupItem,
  Panel,
  Table
} from 'react-bootstrap';
import { FIELD } from '../../../../../formGroups';
import { FIELD as FIELDS_FIELD } from '../../../../../fields';
import withLocalize from '../../../../utils/withLocalize';
import { Consumer, DISPLAY_FORMAT } from '../ChooseDisplayFormat';
import { Fields } from './Fields';
import Type from './Field/Type';

export default (({
  formGroup: {
    [FIELD.ELEMENTS]: elements,
    [FIELD.LIST]: list,
    [FIELD.TITLE]: title
  },
  value,
  fields,
  formGroups,
  localize,
  includeCondensed
}) => (
  <Consumer>
    {displayFormat =>
      list
        ? do {
            ({
              [DISPLAY_FORMAT.CONDENSED]: () => {
                if (!includeCondensed) {
                  return null;
                }
                const columns = Object.entries(
                  filter({
                    elements: pickBy(elements, ({ type }) =>
                      [TYPE.FIELD].includes(type)
                    ),
                    formData: value
                  })
                );
                return (
                  <tr>
                    <td colSpan={2}>
                      {localize(title)}
                      <Table style={{ tableLayout: 'fixed' }}>
                        <thead>
                          <tr>
                            {columns?.map(([_index, column]) => column)
                              .map(({ id }, index) => (
                                <th key={index}>
                                  {localize(
                                    fields.find(({ _id }) => _id === id)[
                                      [FIELDS_FIELD.NAME]
                                    ]
                                  )}
                                </th>
                              ))}
                          </tr>
                        </thead>
                        <tbody>
                          {value?.map((value, index) => (
                              <tr key={index}>
                                {columns.map(([index, { id }]) => (
                                  <td key={index}>
                                    <Type
                                      field={fields.find(
                                        ({ _id }) => _id === id
                                      )}
                                      value={value[index]}
                                    />
                                  </td>
                                ))}
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </td>
                  </tr>
                );
              },
              [DISPLAY_FORMAT.LIST]: () => (
                <>
                  <h4>{localize(title)}</h4>
                  <Panel.Body>
                    {value?.map((value, index) => (
                      <Fragment key={index}>
                        {index ? <hr /> : null}
                        <FormGroup key={index}>
                          <Fields
                            elements={elements}
                            fields={fields}
                            formGroups={formGroups}
                            values={value}
                          />
                        </FormGroup>
                      </Fragment>
                    ))}
                  </Panel.Body>
                </>
              ),
              [DISPLAY_FORMAT.PANEL]: () => (
                <Panel>
                  <Panel.Heading>{localize(title)}</Panel.Heading>
                  <ListGroup>
                    {value?.map((value, index) => (
                      <ListGroupItem key={index}>
                        <Fields
                          elements={elements}
                          fields={fields}
                          formGroups={formGroups}
                          values={value}
                        />
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </Panel>
              ),
              [DISPLAY_FORMAT.TABLE]: () =>
                value &&
                value.map((valueEntry, index) => (
                  <tr key={index}>
                    {index ? null : (
                      <td rowSpan={value.length}>{localize(title)}</td>
                    )}
                    <td>
                      <Fields
                        elements={elements}
                        fields={fields}
                        formGroups={formGroups}
                        values={valueEntry}
                      />
                    </td>
                  </tr>
                ))
            }[displayFormat]());
          }
        : do {
            const element = (
              <Fields
                elements={elements}
                fields={fields}
                formGroups={formGroups}
                isNested
                values={value}
              />
            );
            ({
              [DISPLAY_FORMAT.CONDENSED]: () => includeCondensed ? element : null,
              [DISPLAY_FORMAT.LIST]: () => (
                <>
                  <h4>{localize(title)}</h4>
                  <Panel.Body>{element}</Panel.Body>
                </>
              ),
              [DISPLAY_FORMAT.PANEL]: () => (
                <Panel>
                  <Panel.Heading>{localize(title)}</Panel.Heading>
                  <Panel.Body>{element}</Panel.Body>
                </Panel>
              ),
              [DISPLAY_FORMAT.TABLE]: () => (
                <tr>
                  <td>{localize(title)}</td>
                  <td>{element}</td>
                </tr>
              )
            }[displayFormat]());
          }
    }
  </Consumer>
)) |> withLocalize;
