import React from 'react';
import FormattedDatetime from '../../utils/FormattedDatetime';
import { FormattedMessage } from 'react-intl';
import messages from './intl/messages.properties';

export default ({ index, item }) => (
  <tr>
    <td>
      <FormattedDatetime value={new Date(item.date)}/>
    </td>

    <td>
      <FormattedMessage id={item.approved ? messages.approved : messages.notApproved}/>
    </td>

    <td>
      {item.teamsInfo != null && item.teamsInfo.joinUrl != null
        ? <a href={item.teamsInfo.joinUrl}><FormattedMessage id={messages.linktext}/></a>
        : <FormattedMessage id={messages.linkerror}/>
      }
    </td>

    <td>
      {item.department}
    </td>
  </tr>
);
