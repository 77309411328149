import { Fragment } from 'react';
import { Table } from 'react-bootstrap';
import { pickBy } from 'lodash';
import filter from '@visma/form-ula/es/filter';
import * as TYPE from '@visma/form-ula/es/constants/elementTypes';
import connect from '../../../../../utils/react-refetch/connectAndRenderOnFulfilled';
import withLocalize from '../../../../utils/withLocalize';
import { Consumer, DISPLAY_FORMAT } from '../ChooseDisplayFormat';
import Field from './Field';
import FormGroup from './FormGroup';

export const Fields =
  (({ elements, fields, formGroups, localize, isNested, values = {} }) => (
    <Consumer>
      {displayFormat => do {
        const element = Object.entries(
          filter({
            elements: pickBy(elements, ({ type }) =>
              [
                TYPE.FIELD,
                TYPE.FORMGROUP,
                TYPE.PAGE_TITLE,
                TYPE.TITLE
              ].includes(type)
            ),
            formData: values
          })
        ).map(
          ([index, { id, content, type, includeCondensed }]) => do {
            const element = {
              [TYPE.FIELD]: () => (
                <Field
                  field={fields.find(({ _id }) => _id === id)}
                  value={values[index]}
                  includeCondensed={isNested || includeCondensed}
                />
              ),
              [TYPE.FORMGROUP]: () => (
                <FormGroup
                  fields={fields}
                  formGroup={formGroups.find(({ _id }) => _id === id)}
                  value={values[index]}
                  includeCondensed={includeCondensed}
                />
              ),
              [TYPE.PAGE_TITLE]: () => do {
                const element = <h3>{localize(content)}</h3>;
                ({
                  [DISPLAY_FORMAT.CONDENSED]: (
                    <tr>
                      <td>{element}</td>
                    </tr>
                  ),
                  [DISPLAY_FORMAT.LIST]: element,
                  [DISPLAY_FORMAT.PANEL]: element,
                  [DISPLAY_FORMAT.TABLE]: (
                    <tr>
                      <td>{element}</td>
                    </tr>
                  )
                }[displayFormat]);
              },
              [TYPE.TITLE]: () => do {
                const element = <h4>{localize(content)}</h4>;
                ({
                  [DISPLAY_FORMAT.CONDENSED]: null,
                  [DISPLAY_FORMAT.LIST]: element,
                  [DISPLAY_FORMAT.PANEL]: element,
                  [DISPLAY_FORMAT.TABLE]: (
                    <tr>
                      <td>{element}</td>
                    </tr>
                  )
                }[displayFormat]);
              }
            }[type]();
            ({
              [DISPLAY_FORMAT.CONDENSED]: (
                <Fragment key={index}>{element}</Fragment>
              ),
              [DISPLAY_FORMAT.LIST]: <Fragment key={index}>{element}</Fragment>,
              [DISPLAY_FORMAT.PANEL]: (
                <Fragment key={index}>{element}</Fragment>
              ),
              [DISPLAY_FORMAT.TABLE]: <Fragment key={index}>{element}</Fragment>
            }[displayFormat]);
          }
        );
        ({
          [DISPLAY_FORMAT.CONDENSED]: isNested ? (
            element
          ) : (
            <Table style={{ tableLayout: 'fixed' }}>
              <tbody>{element}</tbody>
            </Table>
          ),
          [DISPLAY_FORMAT.LIST]: element,
          [DISPLAY_FORMAT.PANEL]: element,
          [DISPLAY_FORMAT.TABLE]: (
            <Table style={{ tableLayout: 'fixed' }}>
              <tbody>{element}</tbody>
            </Table>
          )
        }[displayFormat]);
      }}
    </Consumer>
  )) |> withLocalize;

export default connect(() => ({
  fields: '/api/field',
  formGroups: '/api/formgroup'
}))(Fields);
