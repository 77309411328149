import { Label } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import * as TYPE from '@visma/form-ula/es/constants/elementTypes';
import messages from '../intl/messages.properties';

export default ({ elements }) => (
  <Label bsStyle="info" style={{ fontSize: 10.5 }}>
    <FormattedMessage
      id={messages.fieldCount}
      values={{
        count:
          elements?.filter(
            ({ type }) => type === TYPE.FIELD || type === TYPE.FORMGROUP
          ).length ?? 0
      }}
    />
  </Label>
);
