import { mapValues } from 'lodash';
import { withToken } from '../../../components/App/Authorize';

export default connect => propsToOptions => Component =>
  withToken(
    connect(({ token, ...otherProps }) =>
      (function addAuthorizationHeader(options) {
        return mapValues(options, option =>
          ({
            function: () => params => addAuthorizationHeader(option(params)),
            object: () => ({
              ...mapValues(
                option,
                option =>
                  typeof option === 'function'
                    ? params => addAuthorizationHeader(option(params))
                    : option
              ),
              headers: { ...option.headers, Authorization: token }
            })
          }[typeof option]())
        );
      })(propsToOptions(otherProps))
    )(Component)
  );
