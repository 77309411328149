import { FIELD } from '../../../../formGroups';

export default ({
  [String(FIELD.ELEMENTS)]: elements,
  [String(FIELD.LIST)]: list,
  [String(FIELD.NOTES)]: notes,
  [String(FIELD.TITLE)]: title,
  [String(FIELD.USE_LABEL)]: useLabel,
  [String(FIELD.LABEL)]: label,
  ...rest
}) => ({
  ...rest,
  elements,
  list,
  notes,
  title,
  useLabel,
  label
});
