import * as TYPE from '@visma/form-ula/es/constants/fieldTypes';
import { FIELD } from '../../../fields';

export default [
  {
    [FIELD.TYPE]: TYPE.TEXT,
    [FIELD.NAME]: {
      fi: 'Tekstikenttäesimerkkikysymys',
      en: 'Text field example field'
    },
    [FIELD.DESCRIPTION]: {
      fi: 'Kuvausteksti',
      en: 'Description text'
    },
    [FIELD.HELP]: {
      fi: 'Ohjeteksti',
      en: 'Help text'
    },
    [FIELD.NOTES]:
      'Tässä tärkeitä muistiinpanoja lomakkeen laatijalle koskien tekstikenttäesimerkkikysymystä.',
    [FIELD.PATTERN]: '^\\d*$',
    [FIELD.PATTERN_DESCRIPTION]: {
      fi: 'numeroita',
      en: 'numbers'
    }
  },
  {
    [FIELD.TYPE]: TYPE.TEXTAREA,
    [FIELD.NAME]: {
      fi: 'Monirivinen tekstikenttäesimerkkikysymys',
      en: 'Text area example field'
    },
    [FIELD.DESCRIPTION]: {
      fi: 'Kuvausteksti',
      en: 'Description text'
    },
    [FIELD.HELP]: {
      fi: 'Ohjeteksti',
      en: 'Help text'
    },
    [FIELD.LIST]: true
  },
  {
    [FIELD.TYPE]: TYPE.SELECT,
    [FIELD.NAME]: {
      fi: 'Radiopainike-esimerkkikysymys',
      en: 'Radio button example field'
    },
    [FIELD.DESCRIPTION]: {
      fi: 'Kuvausteksti',
      en: 'Description text'
    },
    [FIELD.HELP]: {
      fi: 'Ohjeteksti',
      en: 'Help text'
    },
    [FIELD.CHOICES]: [
      {
        fi: 'Ensimmäinen vaihtoehto'
      },
      {
        fi: 'Toinen vaihtoehto'
      },
      {
        fi: 'Kolmas vaihtoehto'
      }
    ],
    [FIELD.SELECT_WIDGET]: 0
  },
  {
    [FIELD.TYPE]: TYPE.SELECT,
    [FIELD.NAME]: {
      fi: 'Pudotusvalikkoesimerkkikysymys',
      en: 'Select box example field'
    },
    [FIELD.DESCRIPTION]: {
      fi: 'Kuvausteksti',
      en: 'Description text'
    },
    [FIELD.HELP]: {
      fi: 'Ohjeteksti',
      en: 'Help text'
    },
    [FIELD.CHOICES]: [
      {
        fi: 'Ensimmäinen vaihtoehto'
      },
      {
        fi: 'Toinen vaihtoehto'
      },
      {
        fi: 'Kolmas vaihtoehto'
      }
    ]
  },
  {
    [FIELD.TYPE]: TYPE.MULTISELECT,
    [FIELD.NAME]: {
      fi: 'Monivalintaesimerkkikysymys',
      en: 'Multiselect example field'
    },
    [FIELD.DESCRIPTION]: {
      fi: 'Kuvausteksti',
      en: 'Description text'
    },
    [FIELD.HELP]: {
      fi: 'Ohjeteksti',
      en: 'Help text'
    },
    [FIELD.CHOICES]: [
      {
        fi: 'Ensimmäinen vaihtoehto'
      },
      {
        fi: 'Toinen vaihtoehto'
      },
      {
        fi: 'Kolmas vaihtoehto'
      }
    ]
  },
  {
    [FIELD.TYPE]: TYPE.MULTISELECT,
    [FIELD.NAME]: {
      fi: 'Valintapainikkeet-esimerkkikysymys',
      en: 'Checkbox example field'
    },
    [FIELD.DESCRIPTION]: {
      fi: 'Kuvausteksti',
      en: 'Description text'
    },
    [FIELD.HELP]: {
      fi: 'Ohjeteksti',
      en: 'Help text'
    },
    [FIELD.CHOICES]: [
      {
        fi: 'Ensimmäinen vaihtoehto'
      },
      {
        fi: 'Toinen vaihtoehto'
      },
      {
        fi: 'Kolmas vaihtoehto'
      }
    ],
    [FIELD.MULTISELECT_WIDGET]: 0
  },
  {
    [FIELD.TYPE]: TYPE.BOOLEAN,
    [FIELD.NAME]: {
      fi: 'Valintapainike-esimerkkikysymys',
      en: 'Boolean example field'
    },
    [FIELD.DESCRIPTION]: {
      fi: 'Kuvausteksti',
      en: 'Description text'
    },
    [FIELD.HELP]: {
      fi: 'Ohjeteksti',
      en: 'Help text'
    },
    [FIELD.YES]: {
      fi: 'Kyllä',
      en: 'Yes'
    },
    [FIELD.NO]: {
      fi: 'Ei',
      en: 'No'
    }
  },
  {
    [FIELD.TYPE]: TYPE.BOOLEAN,
    [FIELD.NAME]: {
      fi: 'Valintapainike-esimerkkikysymys radiopainikkeilla ja alkuarvolla',
      en: 'Boolean example field with radio buttons'
    },
    [FIELD.DESCRIPTION]: {
      fi: 'Kuvausteksti',
      en: 'Description text'
    },
    [FIELD.HELP]: {
      fi: 'Ohjeteksti',
      en: 'Help text'
    },
    [FIELD.BOOLEAN_WIDGET]: 0,
    [FIELD.YES]: {
      fi: 'Kyllä',
      en: 'Yes'
    },
    [FIELD.NO]: {
      fi: 'Ei',
      en: 'No'
    },
    [FIELD.BOOLEAN_DEFAULT]: true
  },
  {
    [FIELD.TYPE]: TYPE.BOOLEAN,
    [FIELD.NAME]: {
      fi: 'Valintapainike-esimerkkikysymys painikkeilla',
      en: 'Boolean example field with default value'
    },
    [FIELD.DESCRIPTION]: {
      fi: 'Kuvausteksti',
      en: 'Description text'
    },
    [FIELD.HELP]: {
      fi: 'Ohjeteksti',
      en: 'Help text'
    },
    [FIELD.BOOLEAN_WIDGET]: 3,
    [FIELD.YES]: {
      fi: 'Kyllä',
      en: 'Yes'
    },
    [FIELD.NO]: {
      fi: 'Ei',
      en: 'No'
    }
  },
  {
    [FIELD.TYPE]: TYPE.DATE,
    [FIELD.NAME]: {
      fi: 'Päivämäärä'
    }
  }
];
