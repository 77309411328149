import { FIELD } from '../../../../formGroups';

export default ({ elements, list, notes, title, useLabel, label, ...rest }) => ({
  ...rest,
  [FIELD.ELEMENTS]: elements,
  [FIELD.LIST]: list,
  [FIELD.NOTES]: notes,
  [FIELD.TITLE]: title,
  [FIELD.USE_LABEL]: useLabel,
  [FIELD.LABEL]: label
});
