import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';
import { Toggle } from 'react-powerplug';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import common from '../../intl/messages.properties';
import messages from './intl/messages.properties';
import Modal from './Modal';

export default ({ fields, formGroups, elements, index }) => ({
  'ui:field': ({ formData, onChange }) => {
    const noRules =
      formData?.show === undefined && formData?.enable === undefined;
    return (
      <Toggle>
        {({ on, toggle }) => (
          <>
            <span className={noRules ? 'text-muted' : 'text-info'}>
              <FormattedMessage
                id={noRules ? messages.fieldHasNoRules : messages.fieldHasRules}
              />
            </span>{' '}
            <Button bsSize="xsmall" onClick={toggle}>
              <FontAwesomeIcon icon={faEdit} />{' '}
              <FormattedMessage id={common.edit} />
            </Button>
            <Modal
              elements={elements}
              fields={fields}
              formData={formData}
              formGroups={formGroups}
              handleClose={toggle}
              index={index}
              onChange={onChange}
              show={on}
            />
          </>
        )}
      </Toggle>
    );
  },
  'ui:should-update': JSON.stringify(elements)
});
