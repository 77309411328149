import {} from 'lodash';
import handleResponse from 'react-refetch/lib/utils/handleResponse';
import getURLPath from '../../getURLPath';
import formData from './data';
import field from './field';
import form from './form';
import formGroup from './formGroup';

export default connect =>
  connect.defaults({
    buildRequest({ data, url, ...options }) {
      return new Request(url, {
        ...options,
        body:
          data &&
          JSON.stringify(
            {
              '/api/assigned': form,
              '/api/field': field,
              '/api/form': form,
              '/api/formdata': formData,
              '/api/formgroup': formGroup
            }[getURLPath(url)]?.(data) || data
          )
      });
    },
    async handleResponse(response) {
      const responseClone = response.clone();
      try {
        return await handleResponse(response);
      } catch (error) {
        // Handle JSON parse error, when backend returns an empty string.
        if ((await responseClone.text()) === '') {
          if (response.status >= 200 && response.status < 300) {
            return;
          }
        }
        throw error;
      }
    }
  });
