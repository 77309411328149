import { Localize } from '@visma/react-intl-helpers';
import { BrowserRouter } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import moment from 'moment';
import Pages from '../Pages';
import Authorize from './Authorize';
import Navbar from './Navbar';

const App = () => (
  <BrowserRouter>
    <Localize onChangeLocale={moment.locale}>
      <Authorize>
        <Navbar />
        <Pages />
      </Authorize>
    </Localize>
  </BrowserRouter>
);
export default hot(module)(App);
