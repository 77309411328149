import { Label, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Dotdotdot from 'react-dotdotdot';
import { FIELD } from '../../forms';
import withLocalize from '../utils/withLocalize';
import messages from '../intl/messages.properties';

export default (
  (({ element: { _id }, formsFetch, localize }) => do {
    const { count, userForms } = formsFetch.reduce(
      (usage, form) =>
        form[FIELD.ELEMENTS].some(({ id }) => id === _id)
          ? {
              count: usage.count + 1,
              userForms: [...usage.userForms, form]
            }
          : usage,
      { count: 0, userForms: [] }
    );
    const label = (
      <Label bsStyle="success" style={{ fontSize: 10.5 }}>
        <FormattedMessage id={messages.usageCount} values={{ count }} />
      </Label>
    );
    count ? (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip id="tooltip">
            <Dotdotdot clamp={4}>
              {userForms
                .map(({ [FIELD.TITLE]: title, _id }) => localize(title))
                .join(', ')}
            </Dotdotdot>
          </Tooltip>
        }
      >
        {label}
      </OverlayTrigger>
    ) : (
      label
    );
  }) |> withLocalize
);
