
import FIELD from '@visma/form-ula/es/constants/fieldIDs';

import './style.scss';
import THXEdit from './edit.js';
import THXRead from './read.js';

export const Read = THXRead;
export const Type = 200;
export const uiSchema = {
  ...(FIELD.LOCALIZE
    ? { 'ui:field': 'TranslatableTHX' }
    : undefined),
  'ui:widget': THXEdit
};
export const schema = {
  pattern: undefined,
  type: 'string'
};

export const type = { enum: Type, enumNames: { fi: 'THX' } };
