import React, { createContext } from 'react';
import {
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTable } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import NoPrint from '../../../../NoPrint';
import messages from './intl/messages.properties';

const CONDENSED = 'condensed';
const LIST = 'list';
const TABLE = 'table';
const PANEL = 'panel';
export const defaultFormat = CONDENSED;
export const DISPLAY_FORMAT = { CONDENSED, LIST, PANEL, TABLE };

export const { Provider, Consumer } = createContext(defaultFormat);

export default ({ value, onChange }) => (
  <NoPrint>
    <ButtonToolbar className="pull-right">
      <ToggleButtonGroup
        type="radio"
        name="formdata-display-format"
        defaultValue={defaultFormat}
        value={value}
        onChange={onChange}
      >
        {[
          { id: CONDENSED, icon: faTable },
          // OMAKYS-140
          //{ id: LIST, icon: faList },
          //{ id: TABLE, icon: faColumns },
          // OMAKHSHP-278
          //{ id: PANEL, icon: faListAlt }
        ].map(({ id, icon }) => (
          <ToggleButton key={id} value={id}>
            <FontAwesomeIcon icon={icon} />{' '}
            <FormattedMessage id={messages[id]} />
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </ButtonToolbar>
  </NoPrint>
);
