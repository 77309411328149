import { Route } from 'react-router-dom';
import { Grid } from 'react-bootstrap';
import { Role, PROFESSIONAL } from '../../Role';
import PatientInfo from '../../PatientInfo';
import List from './List';
import View from './View';

export default ({ match: { path } }) => (
  <Grid>
    <Role is={PROFESSIONAL}>
      <PatientInfo />
    </Role>
    <Route exact path={path} component={List} />
    <Route path={`${path}/:id/view`} component={View} />
  </Grid>
);
