import { Label } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import messages from './intl/messages.properties';

const messageIDs = {
  NEW: 'statusNew',
  DRAFT: 'draft',
  SUBMITTED: 'submitted'
};

const styles = {
  NEW: 'info',
  DRAFT: 'warning',
  SUBMITTED: 'success'
};

export default ({ status }) => (
  <Label bsStyle={styles[status]} style={{ fontSize: 13.5 , marginLeft: "10px"}}>
    <FormattedMessage id={messages[messageIDs[status]]} />
  </Label>
);
