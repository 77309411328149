import React from 'react';
import TimelineEvent from './TimelineEvent';
import { FormattedMessage } from 'react-intl';
import messages from './intl/messages.properties';

export default ({ items, title }) => (
  <>
    <h3><FormattedMessage id={title}/></h3>

    <table style={{ width: '100%' }}>
      <thead>
      <tr>
        <th style={{ width: '25%' }}>
          <FormattedMessage id={messages.appointmentTime}/>
        </th>

        <th style={{ width: '25%' }}>
          <FormattedMessage id={messages.status}/>
        </th>

        <th style={{ width: '25%' }}>
          <FormattedMessage id={messages.joinUrl}/>
        </th>

        <th style={{ width: '25%' }}>
          <FormattedMessage id={messages.department}/>
        </th>
      </tr>
      </thead>

      <tbody>
        {items.length === 0 &&
          <tr>
            <td>
              <FormattedMessage id={messages.noevents}/>
            </td>
          </tr>
        }

        {items.sort((a, b) => new Date(a.date) - new Date(b.date)).map((item, index) => (
          <TimelineEvent key={index} item={item}/>
        ))}
      </tbody>
    </table>
  </>
);
