import { FormattedMessage } from 'react-intl';
import { LinkContainer } from 'react-router-bootstrap';
import ButtonWithoutHref from '../../../../utils/ButtonWithoutHref';
import messages from './intl/messages.properties';

export default ({ form }) => (
  <LinkContainer to={"/forms/" + form.id + "/fill"}>
    <ButtonWithoutHref className="pull-right">
      <FormattedMessage id={messages.fill}/>
    </ButtonWithoutHref>
  </LinkContainer>
);
