import { Redirect } from 'react-router-dom';
import connect from '../../utils/react-refetch/connect';

export default connect(({ apiPath }) => ({
  post: ({ formData }) => ({
    postResponse: {
      url: `/api/${apiPath}`,
      method: 'POST',
      data: formData
    }
  })
}))(
  ({ Editor, post, postResponse = {}, routePath }) =>
    postResponse.fulfilled ? (
      <Redirect to={`/${routePath}/${postResponse.value}/edit`} />
    ) : (
      <Editor onSubmit={post} />
    )
);
