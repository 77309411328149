import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import FormULA from '@visma/form-ula';
import FIELD from '@visma/form-ula/es/constants/fieldIDs';
import * as ELEMENT from '@visma/form-ula/es/constants/elementTypes';
import * as TYPE from '@visma/form-ula/es/constants/fieldTypes';
import common from '../../../../intl/messages.properties';
import messages from './intl/messages.properties';

const SELECT_FORM = -1;

export default ({ assign, formsFetch, localize, value, set }) => (
  <Modal show={value} onHide={() => set(false)}>
    <FormULA
      design={{
        [FIELD.ELEMENTS]: [
          {
            type: ELEMENT.FIELD,
            id: 0,
            required: true
          },
          {
            type: ELEMENT.FIELD,
            id: 1,
            required: true
          },
          {
            type: ELEMENT.FIELD,
            id: 2
          }
        ]
      }}

      fields={[
        {
          [FIELD.TYPE]: SELECT_FORM,
          [FIELD.NAME]: {
            fi: 'Valitse kohdistettava kysely'
          },
          _id: 0
        },
        {
          [FIELD.TYPE]: TYPE.DATE,
          [FIELD.NAME]: {
            fi: 'Voimassaoloaika'
          },
          [FIELD.DATE_DISABLE_BEFORE]: 'today',
          _id: 1
        },
        {
          [FIELD.TYPE]: TYPE.TEXTAREA,
          [FIELD.NAME]: {
            fi: 'Lisätiedot asiakkaalle'
          },
          _id: 2
        }
      ]}

      extraFieldTypes={{
        schema: {
          [SELECT_FORM]: {
            type: 'integer',
            enum: formsFetch.map(({ _id }) => _id),
            enumNames: formsFetch
              .map(({ [FIELD.TITLE]: title }) => title)
              .map(localize)
          }
        }
      }}

      onSubmit={({ formData }) => {
        assign(formData);
        set(false);
      }}
    >
      {form => (
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id={messages.title}/>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>{form}</Modal.Body>

          <Modal.Footer>
            <Button type="submit" bsStyle="success">
              <FormattedMessage id={messages.action}/>
            </Button>

            <Button onClick={() => set(false)}>
              <FormattedMessage id={common.cancel}/>
            </Button>
          </Modal.Footer>
        </>
      )}
    </FormULA>
  </Modal>
);
