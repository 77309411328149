import { Grid } from 'react-bootstrap';
import { Role, CONFIGURER, PROFESSIONAL } from '../../Role';
import Configurator from './Configurator';
import Professional from './Professional';

export default () => (
  <Grid>
    <Role is={CONFIGURER}>
      <Configurator />
    </Role>
    <Role is={PROFESSIONAL}>
      <Professional />
    </Role>
  </Grid>
);
