/* eslint no-console: "off" */

import { Component } from 'react';
import { mapValues } from 'lodash';
import assignments from './assignments';
import datas from './datas';
import forms from './forms';
import fields from './fields';
import formGroups from './formGroups';

const pickWithId = (data, index) => ({
  ...data[index],
  _id: index,
  _rev: 0
});
const id = (item, _id) => ({
  ...item,
  _id,
  _rev: 0
});

export default class Mock extends Component {
  render() {
    const { props, state } = this;
    const { Component, propsToOptions } = props;
    return (
      <Component
        {...props}
        {...state}
        {...mapValues(
          propsToOptions(props),
          option =>
            typeof option === 'function'
              ? data =>
                  option(data)
                  |> (request => {
                    const [[name, { method, url, data }]] = Object.entries(
                      request
                    );
                    const createAndEdit = model => () =>
                      ({
                        POST: () => {
                          console.log('POST', name, data);
                          this.setState({
                            [name]: {
                              fulfilled: true,
                              value: model.push(data) - 1
                            }
                          });
                        },
                        PUT: () => {
                          console.log('PUT', name, data);
                          model[data._id] = data;
                          this.setState({
                            [name]: {
                              fulfilled: true
                            }
                          });
                        }
                      }[method]());
                    const api = {
                      '/api/assigned': createAndEdit(assignments),
                      '/api/field': createAndEdit(fields),
                      '/api/form': createAndEdit(forms),
                      '/api/formdata': createAndEdit(datas),
                      '/api/formgroup': createAndEdit(formGroups)
                    }[url];
                    return api
                      ? api()
                      : do {
                          console.log('Unknown mock API call:', {
                            method,
                            url,
                            name,
                            data
                          });
                          this.setState({
                            [name]: {
                              fulfilled: true
                            }
                          });
                        };
                  })
              : do {
                  const url = typeof option === 'object' ? option.url : option;
                  console.log('GET', url);
                  ({
                    fulfilled: true,
                    value:
                      {
                        '/api/assigned': assignments.map(id),
                        '/api/field': fields.map(id),
                        '/api/form': forms.map(id),
                        '/api/formdata': datas.map(id),
                        '/api/formgroup': formGroups.map(id),
                        '/api/patient': {
                          nationalId: '010101-0101',
                          name: 'Potilas Saarinen'
                        }
                      }[url] ||
                      pickWithId(
                        [
                          { path: 'assigned', data: assignments },
                          { path: 'field', data: fields },
                          { path: 'formdata', data: datas },
                          { path: 'formgroup', data: formGroups },
                          { path: 'form', data: forms }
                        ].find(({ path }) => url.startsWith(`/api/${path}`))
                          .data,
                        Number(url.split('/')[3])
                      )
                  });
                }
        )}
      />
    );
  }
}
