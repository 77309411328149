// List of logo options: src/components/Editor/fields.js
import logo_sydansairaala from './images/logo_sydansairaala.png';

const logos = {
  1: {
    src: logo_sydansairaala,
    alt: "Sydänsairaala"
  }
};

export default logos;
