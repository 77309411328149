import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'react-bootstrap';
import common from '../../intl/messages.properties';
import messages from './intl/messages.properties';

export default ({ action, bsStyle, children, onConfirm, onClose, show }) => (
  <Modal show={show} onHide={onClose}>
    <Modal.Header closeButton>
      <Modal.Title>
        <FormattedMessage id={messages.confirm} />
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>{children}</Modal.Body>
    <Modal.Footer>
      <Button bsStyle={bsStyle} onClick={onConfirm}>
        {action}
      </Button>
      <Button onClick={onClose}>
        <FormattedMessage id={common.cancel} />
      </Button>
    </Modal.Footer>
  </Modal>
);
