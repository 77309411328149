import { connect } from 'react-refetch';
import backend from './backend';
import frontend from './frontend';
import authorizationHeader from './authorizationHeader';
import normalizePropsToOptions from './normalizePropsToOptions';

export default connect
  |> backend
  |> authorizationHeader
  |> normalizePropsToOptions
  |> frontend;
