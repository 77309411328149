import { injectIntl } from 'react-intl';

export const getLanguage = locale => locale.split('-')[0];

export default Component =>
  injectIntl(({ intl: { locale }, ...otherProps }) => (
    <Component
      {...otherProps}
      localize={messages => messages?.[getLanguage(locale)] || ''}
    />
  ));
