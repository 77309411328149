import * as TYPE from '@visma/form-ula/es/constants/elementTypes';

// Filter undefined elements or elements without IDs.
export default (elements = []) =>
  elements
    .filter(element => element)
    .filter(
      ({ id, type }) =>
        (type !== TYPE.FIELD && type !== TYPE.FORMGROUP) || id !== undefined
    );
