import { Label } from 'react-bootstrap';
import { FIELD } from '../../../../../../../fields';
import withLocalize from '../../../../../../utils/withLocalize';

export default (({
  localize,
  field: { [FIELD.YES]: yes, [FIELD.NO]: no },
  value
}) => (
  <Label bsStyle={value ? 'success' : 'danger'}>
    {value ? localize(yes) : localize(no)}{' '}
    {value === undefined && localize({ fi: '(alkuarvo)' })}
  </Label>
)) |> withLocalize;
