import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import messages from './intl/messages.properties';

export default props => (
  <Button {...props} onClick={global.print}>
    <FontAwesomeIcon icon={faPrint} /> <FormattedMessage id={messages.print} />
  </Button>
);
