import FormULA from '@visma/form-ula';
import * as ELEMENT from '@visma/form-ula/es/constants/elementTypes';
import { FIELD as FORM_FIELD } from '../../../forms';
import elements, {
  convertFormData,
  FIELD as FORMGROUP_FIELD
} from '../../../formGroups';
import Editor from '../../Editor';
import filterIncompleteElements from '../../Editor/filterIncompleteElements';
import withFields from '../Fields/withFields';
import withFormGroups from './withFormGroups';
import {CustomFieldsSchema, CustomFieldsUISchema} from "../../CustomFields";

export default (({ fields, formGroups, ...props }) => (
  <Editor
    {...props}
    design={{
      [FORM_FIELD.TITLE]: { fi: 'Kysymysryhmä' },
      [FORM_FIELD.ELEMENTS]: elements
    }}
    Preview={({ formData }) => (
      <FormULA
        design={{
          [FORM_FIELD.ELEMENTS]: [
            {
              type: ELEMENT.FORMGROUP,
              id: -1
            }
          ]
        }}
        fields={fields}
        formGroups={[
          ...formGroups,
          {
            ...convertFormData({
              ...formData,
              [FORMGROUP_FIELD.ELEMENTS]: filterIncompleteElements(
                formData?.[FORMGROUP_FIELD.ELEMENTS]
              )
            }),
            _id: -1
          }
        ]}
        extraFieldTypes={{
          schema : {...CustomFieldsSchema},
          uiSchema: () => ({...CustomFieldsUISchema})
        }}
      />
    )}
  />
))
  |> withFields
  |> withFormGroups;
