import { FIELD } from '../../../../fields';

export default ({
  list,
  type,
  name,
  useLabel,
  label,
  description,
  help,
  notes,
  selectWidget,
  multiselectWidget,
  choices,
  pattern,
  patternDescription,
  ...rest
}) => ({
  ...rest,
  [FIELD.LIST]: list,
  [FIELD.TYPE]: type,
  [FIELD.NAME]: name,
  [FIELD.USE_LABEL]: useLabel,
  [FIELD.LABEL]: label,
  [FIELD.DESCRIPTION]: description,
  [FIELD.HELP]: help,
  [FIELD.NOTES]: notes,
  [FIELD.SELECT_WIDGET]: selectWidget,
  [FIELD.MULTISELECT_WIDGET]: multiselectWidget,
  [FIELD.CHOICES]: choices,
  [FIELD.PATTERN]: pattern,
  [FIELD.PATTERN_DESCRIPTION]: patternDescription
});
