import { FIELD } from '../../../../../forms';
import connect from '../../../../../utils/react-refetch/connectAndRenderOnFulfilled';
import Fields from './Fields';
import FormULA from '@visma/form-ula';
import withFields from '../../../Fields/withFields';
import withFormGroups from '../../../FormGroups/withFormGroups';
import { CustomFieldsSchema, CustomFieldsUISchema } from "../../../../CustomFields";
import ObjectFieldTemplate from '../../../Forms/Anestesia';

export default connect(({ data: { form: { id } } }) => ({
  formFetch: `/api/form/${id}`,
}))(({ data: { values, form: { id } }, formFetch, fields, formGroups }) => {
  return formFetch[0]?.fi === 'Anestesia Esilomake' ? (
    <FormULA
        design={formFetch}
        formId={id}
        fields={fields}
        formGroups={formGroups}
        formData={values}
        extraFieldTypes={{
          schema: {...CustomFieldsSchema},
          uiSchema: () => ({...CustomFieldsUISchema})
        }}
        ObjectFieldTemplate={ObjectFieldTemplate}
        disabled={true}
      />
    ) : (
    <Fields elements={formFetch[FIELD.ELEMENTS]} values={values} />
  )
}) |> withFields
   |> withFormGroups
