import { mapValues } from 'lodash';

export default connect => propsToOptions =>
  connect(props =>
    (function normalizeOptions(options) {
      return mapValues(options, option =>
        ({
          function: () => params => normalizeOptions(option(params)),
          object: () =>
            mapValues(
              option,
              option =>
                typeof option === 'function'
                  ? params => normalizeOptions(option(params))
                  : option
            ),
          string: () => ({ url: option })
        }[typeof option]())
      );
    })(propsToOptions(props))
  );
