import { mapValues, pickBy } from 'lodash';
import connect from './connect';

export default propsToOptions => Component =>
  connect(propsToOptions)(
    props => do {
      const requests = pickBy(
        propsToOptions(props),
        option => typeof option !== 'function'
      );
      Object.keys(requests).every(name => props[name].fulfilled) ? (
        <Component
          {...props}
          {...mapValues(requests, (option, name) => props[name].value)}
        />
      ) : null;
    }
  );
