import { convertFormData } from '../../../formGroups';
import connect from '../../../utils/react-refetch/connectAndRenderOnFulfilled';

export default ComposedComponent =>
  connect(() => ({
    formGroupsFetch: '/api/formgroup'
  }))(({ formGroupsFetch, ...props }) => (
    <ComposedComponent
      {...props}
      formGroups={formGroupsFetch.map(convertFormData)}
    />
  ));
